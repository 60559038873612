import React from "react";
import Datepicker from "react-tailwindcss-datepicker";
import Typography from "~/components/Typography";
import date from "../../utils/dates/date";
import useDatePicker, { DatePickerState } from "./useDatePicker";
import "./datePicker.css";

interface Props {
  id: string;
  className?: string;
  labelPlacement?: "top" | "left";
  format?: string;
  state: DatePickerState;
  setState: React.Dispatch<React.SetStateAction<DatePickerState>>;
  label: string;
  errorMessage?: string;
  minDate?: Date;
  maxDate?: Date;
  range?: boolean;
  required?: boolean;
}

const DatePicker = ({
  id,
  className,
  labelPlacement = "top",
  format = "MM/DD/YYYY",
  state,
  setState,
  label,
  errorMessage,
  minDate,
  maxDate,
  range = false,
  required = false,
}: Props) => {
  const handleValueChange = ({
    startDate,
    endDate,
  }: {
    startDate: string;
    endDate: string;
  }) => {
    setState((prevState) => {
      let valid;
      if (range) {
        valid = date(startDate).isValid() && date(endDate).isValid();
      } else if (!startDate) {
        valid = false;
      } else {
        valid = date(startDate).isValid();
      }
      return {
        ...prevState,
        value: { startDate, endDate: range ? endDate : startDate },
        valid,
        pristine: false,
        touched: true,
      };
    });
  };

  const showError = state.touched && !state.pristine && !state.valid;

  return (
    <label
      htmlFor={id}
      data-testid={id}
      className={`w-full ${className}${
        labelPlacement === "left" ? " flex items-center gap-1" : ""
      }`}
    >
      <div className="flex flex-row">
        <Typography
          tag="span"
          size="xs"
          className={`block truncate${
            state.value ? " font-medium" : " font-normal"
          }${label && " mb-1"}`}
        >
          {label}
        </Typography>
        {required && (
          <Typography
            tag="span"
            size="2xs"
            className={`${state.disabled ? " text-neutral-75" : ""}`}
          >
            *
          </Typography>
        )}
      </div>
      <div className="datepicker" data-testid={`${id}-datepicker-wrapper`}>
        <Datepicker
          inputId={id}
          minDate={state.minDate ? date(state.minDate).toDate() : minDate}
          maxDate={maxDate}
          asSingle={!range}
          useRange={range}
          value={state.value}
          displayFormat={format}
          separator={range ? "to" : undefined}
          onChange={handleValueChange}
          popoverDirection="down"
          inputClassName={`rounded w-full border border-solid ${
            showError ? "border-red-300" : "border-gray-300"
          } p-[0.57rem] h-[42px] focus:outline-none focus-visible:border-green-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-green-300`}
          readOnly
        />
      </div>
      {showError && (
        <p
          className="text-red-500 text-xs italic p-1"
          data-testid={`${id}-error`}
        >
          {state.errorMessage || errorMessage}
        </p>
      )}
    </label>
  );
};

export { useDatePicker };
export default DatePicker;
