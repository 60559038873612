import React from "react";
import Input from "~/components/Input";
import Combobox from "~/components/Combobox/Combobox";
import Select from "~/components/Select";
import DatePicker from "~/components/DatePicker";
import { State as ComboboxState } from "~/components/Combobox/Combobox.types";
import { SelectState } from "~/components/Select/Select.types";
import { DatePickerState } from "~/components/DatePicker/useDatePicker";
import SelectDepartment from "~/components/SelectDepartment";
import Divider from "~/components/Divider";
import Checkbox from "~/components/Checkbox";
import getCurrencyInputLabel from "~/utils/getCurrencyInputLabel";
import Typography from "~/components/Typography";
import CurrencyInput from "./CurrencyInput";
import InputState = Types.InputState;

interface Props {
  mode: "create" | "edit";
  jobTitle: InputState;
  setJobTitle: React.Dispatch<React.SetStateAction<InputState>>;
  managerState: ComboboxState;
  setManagerState: React.Dispatch<React.SetStateAction<ComboboxState>>;
  selectDepartment: SelectState;
  setSelectDepartment: React.Dispatch<React.SetStateAction<SelectState>>;
  startDateState: DatePickerState;
  setStartDateState: React.Dispatch<React.SetStateAction<DatePickerState>>;
  employmentType: SelectState;
  setEmploymentType: React.Dispatch<React.SetStateAction<SelectState>>;
  selectedPaymentType: SelectState;
  setSelectedPaymentType: React.Dispatch<React.SetStateAction<SelectState>>;
  paymentAmountState: InputState;
  setPaymentAmountState: React.Dispatch<React.SetStateAction<InputState>>;
  bonusAmountState: InputState;
  setBonusAmountState: React.Dispatch<React.SetStateAction<InputState>>;
  commissionAmountState: InputState;
  setCommissionAmountState: React.Dispatch<React.SetStateAction<InputState>>;
  attainmentState: InputState;
  setAttainmentState: React.Dispatch<React.SetStateAction<InputState>>;
  currencyTypeState: SelectState;
  setCurrencyTypeState: React.Dispatch<React.SetStateAction<SelectState>>;
  errorMessage: string;
  changeDescription?: InputState;
  setChangeDescription?: React.Dispatch<React.SetStateAction<InputState>>;
  expectedHoursState: InputState;
  setExpectedHoursState: React.Dispatch<React.SetStateAction<InputState>>;
  useSameDetailsCheckbox: boolean;
  setUseSameDetailsCheckbox: React.Dispatch<React.SetStateAction<boolean>>;
}

const BackfillForm = ({
  mode,
  jobTitle,
  setJobTitle,
  managerState,
  setManagerState,
  selectDepartment,
  setSelectDepartment,
  startDateState,
  setStartDateState,
  employmentType,
  setEmploymentType,
  selectedPaymentType,
  setSelectedPaymentType,
  paymentAmountState,
  setPaymentAmountState,
  bonusAmountState,
  setBonusAmountState,
  commissionAmountState,
  setCommissionAmountState,
  attainmentState,
  setAttainmentState,
  currencyTypeState,
  setCurrencyTypeState,
  errorMessage,
  changeDescription,
  setChangeDescription,
  expectedHoursState,
  setExpectedHoursState,
  useSameDetailsCheckbox,
  setUseSameDetailsCheckbox,
}: Props): React.ReactNode => (
  <div className="container px-0">
    <div className="flex w-full mb-4">
      <DatePicker
        id="effectiveAt"
        state={startDateState}
        setState={setStartDateState}
        label="Backfill Date"
        errorMessage="Start date is required"
        required
      />
    </div>
    <div className="my-4">
      <Divider />
    </div>
    <div className="w-full flex flex-col">
      <div className="flex items-center gap-2 mb-4">
        <Checkbox
          checked={useSameDetailsCheckbox}
          toggleValue={() => setUseSameDetailsCheckbox((prev) => !prev)}
          className="cursor-pointer w-4 h-4"
        />
        <p>Use the same position details as before</p>
      </div>
      <p
        className={`text-lg font-bold mb-1 ${
          useSameDetailsCheckbox ? "text-neutral-75" : ""
        }`}
      >
        Position Details
      </p>
      <div className="w-full flex flex-col gap-4 mb-2">
        <Input
          id="jobTitle"
          label="Job Title"
          state={jobTitle}
          setState={setJobTitle}
          placeholder="Job title"
          required
        />
        <Combobox
          id="select-manager-combobox"
          label="Manager"
          state={managerState}
          setState={setManagerState}
          errorMessage="Manager is required"
          className="hidden"
        />
        <SelectDepartment
          departmentOptions={selectDepartment}
          setDepartmentOptions={setSelectDepartment}
        />
      </div>
      <div className="my-4">
        <Divider />
      </div>
      <p
        className={`text-lg font-bold mb-1 ${
          useSameDetailsCheckbox ? "text-neutral-75" : ""
        }`}
      >
        Compensation
      </p>
      <div className="w-full flex flex-col gap-4 mb-2">
        <Select
          id="employmentTypeSelect"
          label="Employment Type"
          state={employmentType}
          setState={setEmploymentType}
          required
        />
        <Select
          id="paymentTypeSelect"
          label="Payment Type"
          state={selectedPaymentType}
          setState={setSelectedPaymentType}
          required
        />
        <CurrencyInput
          paymentAmountState={paymentAmountState}
          currencyTypeState={currencyTypeState}
          setPaymentAmountState={setPaymentAmountState}
          setCurrencyTypeState={setCurrencyTypeState}
          label={getCurrencyInputLabel(selectedPaymentType)}
          id="paymentAmount"
          required
        />
        {!Array.isArray(selectedPaymentType.selected) &&
          selectedPaymentType.selected?.value === "HOURLY" && (
            <Input
              id="expectedHours"
              type="text"
              label="Expected Hours Per Week"
              state={expectedHoursState}
              setState={setExpectedHoursState}
              placeholder="Expected hours"
            />
          )}
        <div className="flex w-full items-start gap-2">
          <div className="w-5/12">
            <CurrencyInput
              paymentAmountState={bonusAmountState}
              currencyTypeState={currencyTypeState}
              setPaymentAmountState={setBonusAmountState}
              setCurrencyTypeState={setCurrencyTypeState}
              label="Bonus"
              id="bonus"
              hideCurrencyType
            />
          </div>
          <div className="border br-0 mx-2 border-neutral-50 h-[72px]" />
          <div className="w-5/12">
            <CurrencyInput
              paymentAmountState={commissionAmountState}
              currencyTypeState={currencyTypeState}
              setPaymentAmountState={setCommissionAmountState}
              setCurrencyTypeState={setCurrencyTypeState}
              label="Commission"
              id="commission"
              hideCurrencyType
            />
          </div>
          <div className="w-2/12">
            <Input
              label="Attainment"
              id="attainment-percentage"
              state={attainmentState}
              setState={setAttainmentState}
              type="percentage"
            />
          </div>
        </div>
        <Typography size="xs" color="secondary">
          Note: The bonus and commission fields aren’t reconciled by the HRIS
          and any changes will automatically be applied on the indicated
          effective date
        </Typography>
      </div>
    </div>
    {mode === "edit" && changeDescription && setChangeDescription && (
      <div className="mt-2">
        <Input
          id="changeDescription"
          label="Note for Backfill"
          state={changeDescription}
          setState={setChangeDescription}
          placeholder="Add a description of the change you are making for future auditing"
        />
      </div>
    )}
    <div className="flex flex-col mt-6 gap-5">
      {errorMessage && (
        <div className="text-red-500 text-sm">{errorMessage}</div>
      )}
    </div>
  </div>
);

export default BackfillForm;
