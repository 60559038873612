import React, { ReactElement, useEffect, useRef, useState } from "react";
import Header from "~/components/Header";
import { useSelector } from "react-redux";
import { State } from "~/store";
import { useNavigate, useBlocker } from "react-router-dom";
import Button from "~/components/Button";
import Table, { TableData } from "~/components/Table";
import permissionsCheck from "~/utils/permissionsCheck";
import ConfirmPrompt from "~/components/ConfirmPrompt";
import request from "~/utils/request";
import {
  addDays,
  addYears,
  eachMonthOfInterval,
  endOfYear,
  format,
  parseISO,
  startOfYear,
} from "date-fns";
import usePeriodPicker from "~/components/PeriodPicker/usePeriodPicker";
import useQueryParams from "~/utils/hooks/useQueryParams";
import PeriodPicker from "~/components/PeriodPicker";
import SaveAdjustments from "./SaveAdjustments";
import formatCurrency from "~/utils/formatCurrency";
import { ISelectedCellState } from "~/components/Table/TableCell";
import Typography from "~/components/Typography";
import AdjustmentOptionsPopover from "./AdjustmentOptionsPopover";
import toast from "react-hot-toast";
import date from "~/utils/dates/date";
import adjustmentHistoryEmptyState from "~/assets/adjustmentHistoryEmptyState.svg";
import Modal from "~/components/Modal";
import DeleteModel from "./DeleteModel";
import EllipsisDropdown from "~/components/EllipsisDropdown/index";
import { IExpense } from "../expenseModel.types";

interface IProps {
  expenseModel: IExpense;
  organizationUuid: string;
  canBeDeleted?: boolean;
  fetchExpenseDetails: () => void;
}

interface IAdjustment {
  amount: number;
  comment: string;
  createdAt: Date;
  createdBy: string;
  createdByName: string;
  deletedAt: Date | null;
  deletedBy: string | null;
  effectiveAt: Date;
  groupUuid: string;
  updatedAt: Date;
  uuid: string;
  departmentName?: string;
}

interface IAdjustmentsByMonth {
  adjustments: IAdjustment[];
  month: string;
  total: number;
}

interface IAdjustments {
  adjustmentsByMonth: IAdjustmentsByMonth[];
  departmentName: string;
  departmentUuid: string;
}

interface IAdjustmentsResponse {
  data: {
    data: IAdjustments[];
  };
  status: number;
}

const ManualAdjustmentDetails = ({
  expenseModel,
  organizationUuid,
  canBeDeleted,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  fetchExpenseDetails,
}: IProps): ReactElement => {
  const adjustmentHistoryTableRef = useRef();
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [queryParams, setQueryParams] = useQueryParams();
  const {
    permissions: { role, departmentAccessList },
  } = useSelector((state: State) => state.user);
  const [editMode, setEditMode] = useState(false);
  const [adjustments, setAdjustments] = useState<
    Record<
      string,
      { amount: number; departmentUuid: string; effectiveAt: Date }
    >
  >({});
  const [cancelChanges, setCancelChanges] = useState(false);
  const [showSaveAdjustmentsModal, setShowSaveAdjustmentsModal] =
    useState(false);
  const [currentAdjustments, setCurrentAdjustments] = useState<IAdjustments[]>(
    [],
  );
  const [isLoading, setIsLoading] = useState(true);
  const [adjustmentHistoryData, setAdjustmentHistoryData] =
    useState<TableData[]>();
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      Object.entries(adjustments).length > 0 &&
      editMode &&
      currentLocation.pathname !== nextLocation.pathname,
  );
  const [periodDates, setPeriodDates] = usePeriodPicker({
    startDate: parseISO(
      queryParams.get("startDate") ??
        startOfYear(date().toDate()).toISOString(),
    ),
    endDate: parseISO(
      queryParams.get("endDate") ?? endOfYear(date().toDate()).toISOString(),
    ),
    mode: "year",
  });
  const [monthInterval, setMonthInterval] = useState({
    start: periodDates.startDate,
    end: addYears(periodDates.endDate, 1),
  });
  const [selectedCell, setSelectedCell] = useState<ISelectedCellState>();
  const months = eachMonthOfInterval(monthInterval);
  const adjustmentHeaders = [
    "Department",
    ...months.map((dateForMonth) => format(dateForMonth, "MMM yy")),
  ];
  const [showDelete, setShowDelete] = useState<boolean>(false);

  const sortAdjustments = (a: IAdjustment, b: IAdjustment): number => {
    if (a.deletedAt === null && b.deletedAt !== null) return -1;
    if (a.deletedAt !== null && b.deletedAt === null) return 1;

    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  };

  const deleteAdjustment = async (adjustmentUuid: string): Promise<void> => {
    try {
      const response = await request({
        url: `/organizations/${organizationUuid}/expense-models/${expenseModel.expenseUuid}/adjustments/${adjustmentUuid}`,
        method: "DELETE",
      });

      if (response.status >= 400) throw new Error("Error deleting adjustment");

      toast.success("Adjustment deleted successfully");
      setAdjustmentHistoryData((prevData) => {
        if (!prevData) return prevData;
        return prevData.map((row) => {
          if (row.id === adjustmentUuid) {
            return {
              ...row,
              rowColor: "neutral",
              values: row.values.map((cell, index) => {
                if (index === 6) {
                  return {
                    ...cell,
                    value: null,
                  };
                }
                return cell;
              }),
            };
          }
          return row;
        });
      });
      getAdjustments();
    } catch (error) {
      toast.error("Error deleting adjustment");
    }
  };

  const formatWeirdDate = (weirdDate: Date, formatString: string): string =>
    format(addDays(new Date(weirdDate), 1), formatString);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        selectedCell?.ref &&
        adjustmentHistoryTableRef &&
        "current" in adjustmentHistoryTableRef &&
        adjustmentHistoryTableRef.current &&
        !adjustmentHistoryTableRef.current.contains(event.target as Node) &&
        !selectedCell.ref.current?.contains(event.target as Node)
      ) {
        setSelectedCell(undefined);
      }
    };

    const handleEscape = (event: KeyboardEvent): void => {
      if (event.key === "Escape") {
        setSelectedCell(undefined);
      }
    };

    // Bind the event listener
    document.addEventListener("mouseup", handleClickOutside);
    document.addEventListener("keydown", handleEscape);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, [selectedCell?.ref, adjustmentHistoryTableRef]);

  const getAdjustments = async (): Promise<void> => {
    try {
      setIsLoading(true);
      const adjustmentsResponse = (await request({
        url: `/organizations/${organizationUuid}/expense-models/${expenseModel.expenseUuid}/adjustments`,
        method: "GET",
        params: {
          startDate: format(periodDates.startDate, "yyyy-MM-dd"),
          endDate: format(periodDates.endDate, "yyyy-MM-dd"),
        },
      })) as IAdjustmentsResponse;

      if (adjustmentsResponse.status >= 400)
        throw new Error("Error fetching adjustments");

      setCurrentAdjustments(adjustmentsResponse.data.data);

      if (selectedCell) {
        setSelectedCell((prev) => ({
          department: prev?.department as string,
          value: adjustmentsResponse.data.data.find(
            ({ departmentName }) => departmentName === prev?.department,
          )?.adjustmentsByMonth[prev?.rowIndex as number].total,
          rowIndex: prev?.rowIndex as number,
          ref: prev?.ref as React.MutableRefObject<HTMLElement | undefined>,
        }));
      }
    } catch (error) {
      console.error("Error fetching adjustments", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAdjustments();

    setQueryParams({
      mode: periodDates.mode,
      startDate: format(periodDates.startDate, "yyyy-MM-dd"),
      endDate: format(periodDates.endDate, "yyyy-MM-dd"),
    });
    setMonthInterval({
      start: periodDates.startDate,
      end: periodDates.endDate,
    });
  }, [periodDates]);

  useEffect(() => {
    if (!permissionsCheck(role, departmentAccessList)) navigate("/dashboard");
  }, []);

  const onClickCell = (
    departmentName: string,
    adjustments: IAdjustment[],
  ): void => {
    const sortedAdjustments = adjustments.slice().sort(sortAdjustments);
    setAdjustmentHistoryData(
      sortedAdjustments.map(
        (adj): TableData => ({
          id: adj.uuid,
          rowColor: adj.deletedAt ? "neutral" : undefined,
          values: [
            { value: formatCurrency(adj.amount, true) },
            { value: adj.comment },
            { value: departmentName },
            {
              value: formatWeirdDate(adj.effectiveAt, "MMM yyyy"),
            },
            { value: adj.createdByName },
            {
              value: formatWeirdDate(adj.createdAt, "MM/dd/yyyy"),
            },
            {
              value: adj.deletedAt ? null : (
                <AdjustmentOptionsPopover
                  id={`${departmentName}-${format(
                    addDays(new Date(adj.effectiveAt), 1),
                    "MMM yyyy",
                  )}`}
                  deleteFunction={() => {
                    deleteAdjustment(adj.uuid);
                  }}
                />
              ),
            },
          ],
        }),
      ),
    );
  };

  const newTableData = currentAdjustments.length
    ? currentAdjustments.map((departmentAdjustment) => ({
        id: departmentAdjustment.departmentUuid,
        values: [
          {
            value: departmentAdjustment.departmentName,
            metadata: { departmentUuid: departmentAdjustment.departmentUuid },
            tdClassName: "max-w-[250px]",
          },
          ...departmentAdjustment.adjustmentsByMonth.map((adjustment) => ({
            value:
              adjustment.adjustments.filter((adj) => adj.deletedAt === null)
                .length > 0
                ? adjustment.total
                : "",
            editable: true,
            onClickCell: () =>
              onClickCell(
                departmentAdjustment.departmentName,
                adjustment.adjustments,
              ),
            onDoubleClickCell: (): void => {
              setEditMode(true);
              onClickCell(
                departmentAdjustment.departmentName,
                adjustment.adjustments,
              );
            },
            metadata: {
              substring: adjustment.adjustments.filter(
                (adj) => adj.deletedAt === null,
              ).length
                ? adjustment.adjustments.filter((adj) => adj.deletedAt === null)
                    .length
                : undefined,
            },
          })),
        ],
      }))
    : [];

  useEffect(() => {
    if (!selectedCell) {
      const allAdjustments = currentAdjustments.reduce(
        (acc, departmentAdjustment) => {
          const departmentAdjustments =
            departmentAdjustment.adjustmentsByMonth.flatMap((month) => {
              return month.adjustments.map((adj) => ({
                ...adj,
                departmentName: departmentAdjustment.departmentName,
              }));
            });
          return [...acc, ...departmentAdjustments];
        },
        [] as IAdjustment[],
      );

      const sortedAllAdjustments = allAdjustments.sort(sortAdjustments);
      setAdjustmentHistoryData(
        sortedAllAdjustments.map(
          (adj): TableData => ({
            id: adj.uuid,
            rowColor: adj.deletedAt ? "neutral" : undefined,
            values: [
              { value: formatCurrency(adj.amount, true) },
              { value: adj.comment },
              { value: adj.departmentName },
              {
                value: formatWeirdDate(adj.effectiveAt, "MMM yyyy"),
              },
              { value: adj.createdByName },
              {
                value: formatWeirdDate(adj.createdAt, "MM/dd/yyyy"),
              },
              {
                value: adj.deletedAt ? null : (
                  <AdjustmentOptionsPopover
                    id={`${adj.departmentName}-${formatWeirdDate(
                      adj.effectiveAt,
                      "MMM yyyy",
                    )}`}
                    deleteFunction={() => {
                      deleteAdjustment(adj.uuid);
                    }}
                  />
                ),
              },
            ],
          }),
        ),
      );
    }
  }, [selectedCell, currentAdjustments]);

  const adjustmentHistoryHeaders = [
    "Amount",
    "Reason",
    "Department",
    "Applied On",
    "Changed By",
    "Changed On",
    "",
  ];

  return (
    <>
      <SaveAdjustments
        isOpen={showSaveAdjustmentsModal}
        onClose={() => {
          setShowSaveAdjustmentsModal(false);
        }}
        onSave={() => {
          setShowSaveAdjustmentsModal(false);
          setAdjustments({});
          setEditMode(false);
          getAdjustments();
        }}
        adjustments={adjustments}
        organizationUuid={organizationUuid}
        expenseUuid={expenseModel.expenseUuid}
      />
      <ConfirmPrompt
        isOpen={blocker.state === "blocked"}
        onClose={blocker.reset}
        title="Leave Without Saving Changes"
        message="All modifications that have not been saved will be lost."
        confirmButtonText="Leave & Discard Changes"
        onConfirm={blocker.proceed}
      />
      <ConfirmPrompt
        isOpen={cancelChanges}
        onClose={() => {
          setCancelChanges(false);
        }}
        title="Discard Changes"
        message="All modifications that have not been saved will be lost."
        confirmButtonText="Discard Changes"
        onConfirm={() => {
          setCancelChanges(false);
          setEditMode(false);
          setAdjustments({});
        }}
      />
      <Header
        title="Details"
        breadCrumbs={[{ label: "Expense Models", url: "/expense-models" }]}
      >
        <div className="flex gap-3 items-center">
          {editMode ? (
            <div className="flex flex-row gap-4">
              <Button
                id="cancel-edit-adjustment-button"
                className="!w-auto"
                fill="destructiveOutline"
                onClick={() => {
                  if (Object.entries(adjustments).length > 0)
                    setCancelChanges(true);
                  else {
                    setEditMode(false);
                    setAdjustments({});
                  }
                }}
              >
                Cancel
              </Button>
              <Button
                id="save-edit-adjustment-button"
                className="!w-auto"
                onClick={() => setShowSaveAdjustmentsModal(true)}
                disabled={Object.entries(adjustments).length === 0}
              >
                Save
              </Button>
            </div>
          ) : (
            <Button className="!w-auto" onClick={() => setEditMode(true)}>
              Edit
            </Button>
          )}
          {canBeDeleted && (
            <EllipsisDropdown
              id="manual-adjustment-ellipsis-dropdown"
              options={[
                {
                  label: "Delete",
                  className: "text-red-400",
                  onClick: () => setShowDelete(true),
                },
              ]}
              onClick={() => {}}
            />
          )}
        </div>
      </Header>
      <div className="flex flex-col container !max-w-full !pr-0 !pt-10">
        <Typography size="xl" weight="semibold" className="mb-4">
          {expenseModel.current.name}
        </Typography>
        <div className="flex flex-row items-center justify-between mb-[10px] pr-8">
          <Typography size="lg" weight="semibold">
            Adjustments
          </Typography>
          <div className="relative">
            <PeriodPicker
              state={periodDates}
              setState={setPeriodDates}
              pickerAlignment="right"
            />
          </div>
        </div>
        <Table
          id="manual-adjustment-table"
          headers={adjustmentHeaders}
          headerClassName="px-6 py-4"
          columnAlignment={[
            "left",
            "right",
            "right",
            "right",
            "right",
            "right",
            "right",
            "right",
            "right",
            "right",
            "right",
            "right",
            "right",
          ]}
          data={newTableData}
          editMode={editMode}
          edits={adjustments}
          setEdits={setAdjustments}
          loadingState={{ isLoading, skeletonRows: 5 }}
          selectedCell={selectedCell}
          setSelectedCell={setSelectedCell}
        />
        <Typography size="lg" className="mt-6 mb-4" weight="semibold">
          {selectedCell ? "Cell" : "Adjustment"} History
        </Typography>
        <div ref={adjustmentHistoryTableRef}>
          <Table
            id="manual-adjustment-history-table"
            headers={adjustmentHistoryHeaders}
            headerClassName="px-2 py-4"
            columnAlignment={[
              "left",
              "left",
              "left",
              "left",
              "left",
              "left",
              "right",
            ]}
            data={adjustmentHistoryData ?? null}
            loadingState={{ isLoading, skeletonRows: 5 }}
            emptyState={
              !isLoading && (
                <div className="flex flex-col items-center gap-4 py-16">
                  <img
                    src={adjustmentHistoryEmptyState}
                    alt="Empty Tasks Illustration"
                    className="w-32 h-auto"
                  />
                  <Typography color="disabled" size="sm">
                    No History
                  </Typography>
                </div>
              )
            }
          />
        </div>
      </div>
      {showDelete && canBeDeleted && (
        <Modal
          isOpen={showDelete}
          onClose={() => setShowDelete(false)}
          size="xs"
        >
          <DeleteModel
            onClose={() => setShowDelete(false)}
            expenseUuid={expenseModel.expenseUuid}
            organizationUuid={organizationUuid}
          />
        </Modal>
      )}
    </>
  );
};

export default ManualAdjustmentDetails;
