import React from "react";
import { State as ComboboxState } from "~/components/Combobox/Combobox.types";
import { SelectState } from "~/components/Select/Select.types";
import Divider from "~/components/Divider";
import Typography from "~/components/Typography";
import { DatePickerState } from "~/components/DatePicker/useDatePicker";
import date from "~/utils/dates/date";
import DatePicker from "~/components/DatePicker";
import ManuallyTrackedDataForm from "./ManuallyTrackedDataForm";
import CompensationForm from "./CompensationForm";
import RoleDetailForm from "./RoleDetailForm";
import InputState = Types.InputState;

interface Props {
  jobTitle: InputState;
  setJobTitle: React.Dispatch<React.SetStateAction<InputState>>;
  managerState: ComboboxState;
  setManagerState: React.Dispatch<React.SetStateAction<ComboboxState>>;
  selectDepartment: SelectState;
  setSelectDepartment: React.Dispatch<React.SetStateAction<SelectState>>;
  employmentType: SelectState;
  setEmploymentType: React.Dispatch<React.SetStateAction<SelectState>>;
  selectedPaymentType: SelectState;
  setSelectedPaymentType: React.Dispatch<React.SetStateAction<SelectState>>;
  paymentAmountState: InputState;
  setPaymentAmountState: React.Dispatch<React.SetStateAction<InputState>>;
  bonusAmountState: InputState;
  setBonusAmountState: React.Dispatch<React.SetStateAction<InputState>>;
  commissionAmountState: InputState;
  setCommissionAmountState: React.Dispatch<React.SetStateAction<InputState>>;
  attainmentState: InputState;
  setAttainmentState: React.Dispatch<React.SetStateAction<InputState>>;
  currencyTypeState: SelectState;
  setCurrencyTypeState: React.Dispatch<React.SetStateAction<SelectState>>;
  errorMessage: string;
  expectedHoursState: InputState;
  setExpectedHoursState: React.Dispatch<React.SetStateAction<InputState>>;
  startDateState: DatePickerState;
  setStartDateState: React.Dispatch<React.SetStateAction<DatePickerState>>;
}

const CurrentPositionDetailsForm = ({
  jobTitle,
  setJobTitle,
  managerState,
  setManagerState,
  selectDepartment,
  setSelectDepartment,
  employmentType,
  setEmploymentType,
  selectedPaymentType,
  setSelectedPaymentType,
  paymentAmountState,
  setPaymentAmountState,
  bonusAmountState,
  setBonusAmountState,
  commissionAmountState,
  setCommissionAmountState,
  attainmentState,
  setAttainmentState,
  currencyTypeState,
  setCurrencyTypeState,
  errorMessage,
  expectedHoursState,
  setExpectedHoursState,
  startDateState,
  setStartDateState,
}: Props): React.ReactNode => (
  <div className="px-0">
    <div className="w-full flex flex-col">
      <div className="w-full mb-4">
        <DatePicker
          id="effectiveAt"
          minDate={date().toDate()}
          state={startDateState}
          setState={setStartDateState}
          label="Hire Date"
          errorMessage="Start date is required"
        />
      </div>
      <RoleDetailForm
        jobTitle={jobTitle}
        setJobTitle={setJobTitle}
        selectDepartment={selectDepartment}
        setSelectDepartments={setSelectDepartment}
        managerState={managerState}
        setManagerState={setManagerState}
      />
      <div className="my-4">
        <Divider />
      </div>
      <CompensationForm
        employmentType={employmentType}
        setEmploymentType={setEmploymentType}
        selectedPaymentType={selectedPaymentType}
        setSelectedPaymentType={setSelectedPaymentType}
        paymentAmountState={paymentAmountState}
        setPaymentAmountState={setPaymentAmountState}
        currencyTypeState={currencyTypeState}
        setCurrencyTypeState={setCurrencyTypeState}
      />
      <div className="my-4">
        <Divider />
      </div>
      <div className="flex flex-col gap-3">
        <Typography size="sm" weight="semibold">
          Manually Tracked
        </Typography>
        <ManuallyTrackedDataForm
          selectedPaymentType={selectedPaymentType}
          expectedHoursState={expectedHoursState}
          setExpectedHoursState={setExpectedHoursState}
          bonusAmountState={bonusAmountState}
          setBonusAmountState={setBonusAmountState}
          commissionAmountState={commissionAmountState}
          setCommissionAmountState={setCommissionAmountState}
          attainmentState={attainmentState}
          setAttainmentState={setAttainmentState}
          currencyTypeState={currencyTypeState}
          setCurrencyTypeState={setCurrencyTypeState}
        />
      </div>
    </div>
    <div className="flex flex-col mt-6 gap-5">
      {errorMessage && (
        <div className="text-red-500 text-sm">{errorMessage}</div>
      )}
    </div>
  </div>
);

export default CurrentPositionDetailsForm;
