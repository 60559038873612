import React, { useEffect } from "react";
import request from "~/utils/request";
import { useSelector } from "react-redux";
import { State } from "~/store";
import {
  SelectMultipleState,
  SelectMultipleType,
} from "../SelectMultiple/SelectMultiple.types";
import SelectMultiple from "../SelectMultiple";
import arrowTurnRight from "~/assets/arrowTurnRight.svg";

interface Props {
  departmentOptions: SelectMultipleState;
  setDepartmentOptions: React.Dispatch<
    React.SetStateAction<SelectMultipleState>
  >;
  className?: string;
  listBoxClassName?: string;
  label?: string;
  placeholder?: string;
  allowSelectAll?: boolean;
}
interface Department {
  uuid: string;
  name: string;
  parentUuid: string | null;
}
export interface DepartmentResponse {
  data: {
    data: Department[];
  } | null;
  status: number;
}

interface DepartmentHierarchy {
  uuid: string;
  name: string;
  parentUuid: string | null;
  childDepartments: DepartmentHierarchy[];
}

const labelPaddingArray = [
  undefined,
  undefined,
  "pl-[24px]",
  "pl-[48px]",
  "pl-[72px]",
  "pl-[96px]",
  "pl-[120px]",
  "pl-[144px]",
  "pl-[168px]",
];

const SelectMultipleDepartments = ({
  departmentOptions,
  setDepartmentOptions,
  className = "",
  listBoxClassName = "",
  label,
  placeholder,
  allowSelectAll = false,
}: Props): React.ReactNode => {
  const { uuid: organizationUuid } = useSelector(
    (state: State) => state.organization,
  );
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [departments, setDepartments] = React.useState<Department[]>([]);
  const [disabledOptions, setDisabledOptions] = React.useState<string[]>([]);

  useEffect(() => {
    const getDepartments = async (): Promise<void> => {
      const fetchDepartmentsResponse = (await request({
        url: `/organizations/${organizationUuid}/groups`,
        method: "GET",
      })) as DepartmentResponse;
      if (fetchDepartmentsResponse.status >= 400) return;
      if (fetchDepartmentsResponse.data) {
        const { data } = fetchDepartmentsResponse.data;
        const options: SelectMultipleType[] = [];
        setDepartments(data);
        if (allowSelectAll && data.length > 1)
          options.push({ label: "All Departments", value: null });
        setDepartmentOptions((prevState) => ({
          ...prevState,
          selected: allowSelectAll
            ? [{ label: "All Departments", value: null }]
            : prevState.selected,
          options: [
            ...options,
            ...data.map((department) => ({
              label: department.name,
              value: department.uuid,
            })),
          ],
        }));
        const departmentDict = data.reduce(
          (output: Record<string, Department>, department) => {
            const modifiedOutput = output;
            modifiedOutput[department.uuid] = department;
            return modifiedOutput;
          },
          {},
        );

        const buildHierarchy = (
          department: Department,
        ): DepartmentHierarchy => {
          const childDepartments = Object.values(departmentDict).reduce(
            (acc: Department[], group) => {
              if (group.parentUuid === department.uuid) {
                return [...acc, group];
              }
              return acc;
            },
            [],
          );

          return {
            uuid: department.uuid,
            name: department.name,
            parentUuid: department.parentUuid,
            childDepartments: childDepartments.map(buildHierarchy),
          };
        };

        const rootDepartments = Object.entries(departmentDict).reduce(
          (acc: DepartmentHierarchy[], [, department]) => {
            if (department.parentUuid === null) {
              return [...acc, buildHierarchy(department)];
            }
            return acc;
          },
          [],
        );

        const computeDepth = (nodeUuid: string): number => {
          let depth = 0;
          let currentNodeUuid = nodeUuid;
          while (departmentDict[currentNodeUuid].parentUuid !== null) {
            depth += 1;
            currentNodeUuid = departmentDict[currentNodeUuid]
              .parentUuid as string;
          }
          return depth;
        };

        const buildNodeValue = (nodeUuid: string): React.ReactNode | null => {
          const node = departmentDict[nodeUuid];
          const depth = computeDepth(nodeUuid);
          if (depth < 1) {
            return node.name;
          }

          return (
            <div
              title={node.name}
              className={`flex flex-row gap-2 ${labelPaddingArray[depth]}`}
            >
              <img
                src={arrowTurnRight}
                alt="Arrow Turn Right Icon"
                className="w-4 h-auto"
              />
              <div>{node.name}</div>
            </div>
          );
        };

        const convertChildrenToOptions = (
          nodes: DepartmentHierarchy[],
        ): {
          label: string | React.ReactNode;
          value: string | null;
          node?: React.ReactNode | null;
        }[] => {
          const output: {
            label: string | React.ReactNode;
            value: string | null;
            node?: React.ReactNode | null;
          }[] = [];
          if (allowSelectAll && nodes.length > 1) {
            output.push({ label: "All Departments", value: null });
          }
          nodes.forEach((node) => {
            output.push({
              label: node.name,
              value: node.uuid,
              node: buildNodeValue(node.uuid),
            });
            if (node.childDepartments.length > 0) {
              output.push(...convertChildrenToOptions(node.childDepartments));
            }
          });

          return output;
        };

        const departmentsToSet = convertChildrenToOptions(rootDepartments);

        if (departmentsToSet.length === 1) {
          setDepartmentOptions((prevState) => ({
            ...prevState,
            selected: [
              {
                label: departmentsToSet[0].label,
                value: departmentsToSet[0].value,
                node: departmentsToSet[0].node,
              },
            ],
          }));
          setIsDisabled(true);
        } else {
          setIsDisabled(false);
        }

        setDepartmentOptions((prevState) => ({
          ...prevState,
          options: departmentsToSet,
        }));
      }
    };
    getDepartments();
  }, []);

  useEffect(() => {
    const updateOptionsForSelectedParents = (): void => {
      const selectedUuids = departmentOptions.selected?.map(
        (item) => item.value,
      );

      const isAncestorSelected = (departmentUuid: string): boolean => {
        let currentUuid: string | null | undefined = departmentUuid;
        while (currentUuid) {
          const parentUuid = departments.find(
            (dept) => dept.uuid === currentUuid,
          )?.parentUuid;
          if (selectedUuids?.includes(parentUuid)) {
            return true;
          }
          currentUuid = parentUuid;
        }
        return false;
      };

      const recursivelyDisableOptions = (nodes: SelectMultipleType[]): void => {
        nodes.map((node) => {
          const disabled = isAncestorSelected(node.value ?? "");

          if (disabled) {
            setDisabledOptions((prevState) => {
              const updatedDisabledOptions = [
                ...prevState,
                node.value as string,
              ];
              return updatedDisabledOptions;
            });
          } else {
            setDisabledOptions((prevState) => {
              const updatedDisabledOptions = prevState.filter(
                (option) => option !== node.value,
              );
              return updatedDisabledOptions;
            });
          }
        });
      };

      if (departmentOptions.selected && departments.length > 0) {
        recursivelyDisableOptions(departmentOptions.options);
      }
    };

    if (departmentOptions.selected) {
      updateOptionsForSelectedParents();
    }
  }, [departmentOptions.selected, departmentOptions.options, departments]);

  return (
    <SelectMultiple
      id="department-select"
      label={label}
      state={departmentOptions}
      setState={setDepartmentOptions}
      className={className}
      placeholder={placeholder}
      disabled={isDisabled}
      listBoxClassName={listBoxClassName}
      disabledOptions={disabledOptions}
    />
  );
};

export default SelectMultipleDepartments;
