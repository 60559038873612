import React, { useEffect, useRef, useState } from "react";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Cell,
  Legend,
} from "recharts";
import date from "~/utils/dates/date";
import SegmentedControl from "~/components/SegmentedControl";
import request from "~/utils/request";
import { useSelector } from "react-redux";
import useWindowSize from "~/utils/hooks/useWindowSize";
import { State } from "~/store";
import Skeleton from "~/components/Skeleton";
import { Projection, ProjectionsResponse } from "pages/Forecast/types";
import { SelectState } from "~/components/Select/Select.types";
import {
  startOfYear,
  endOfYear,
  format as formatDate,
  addYears,
  isBefore,
  addMonths,
  addQuarters,
  startOfMonth,
  parse,
  differenceInCalendarMonths,
  differenceInCalendarQuarters,
} from "date-fns";
import Card from "~/components/Card";
import LegendContent from "./LegendContent";
import TooltipContent, { Datum } from "./TooltipContent";
import Typography from "~/components/Typography";

interface Props {
  isLoading: boolean;
  planToCompare?: string;
  periodDates: {
    mode: "month" | "quarter" | "year";
    startDate: Date;
    endDate: Date;
  };
  departmentOptions: SelectState;
}

const AnalyticsCard = ({
  isLoading: pageIsLoading,
  planToCompare,
  periodDates,
  departmentOptions,
}: Props): React.ReactNode => {
  const controllerRef = useRef<AbortController>();
  const windowSize = useWindowSize();
  const { uuid: organizationUuid } = useSelector(
    (state: State) => state.organization,
  );
  const [format, setFormat] = useState<Types.DataSubject>("expenses");
  const [data, setData] = useState<Datum[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const isQuarterMode = periodDates.mode === "quarter";
  const { startDate } = periodDates;

  useEffect(() => {
    const getData = async (): Promise<void> => {
      if (controllerRef.current) controllerRef.current.abort();
      controllerRef.current = new AbortController();
      setIsLoading(true);
      try {
        const { signal } = controllerRef.current;
        const actualProjectionsResponse = (await request({
          url: `/organizations/${organizationUuid}/projections`,
          method: "GET",
          params: {
            startDate: formatDate(startOfYear(startDate), "yyyy-MM-dd"),
            endDate: isQuarterMode
              ? formatDate(endOfYear(addYears(startDate, 1)), "yyyy-MM-dd")
              : formatDate(endOfYear(startDate), "yyyy-MM-dd"),
            format,
            departments: departmentOptions.selected?.value
              ? [departmentOptions.selected.value]
              : null,
          },
          signal,
        })) as ProjectionsResponse;

        const actualCurrencyProjections =
          actualProjectionsResponse.data.data.currencyProjections;

        const actualHeadcountProjection =
          actualProjectionsResponse.data.data.headcountProjections;

        const actualProjectionForSelectedView =
          format === "expenses"
            ? actualCurrencyProjections
            : actualHeadcountProjection;

        let planProjectionForSelectedView: Projection[] | undefined;
        if (planToCompare) {
          const planProjectionResponse = (await request({
            url: `/organizations/${organizationUuid}/projections`,
            method: "GET",
            params: {
              startDate: formatDate(startOfYear(startDate), "yyyy-MM-dd"),
              endDate: isQuarterMode
                ? formatDate(endOfYear(addYears(startDate, 1)), "yyyy-MM-dd")
                : formatDate(endOfYear(startDate), "yyyy-MM-dd"),
              format,
              planUuid: planToCompare,
              departments: departmentOptions.selected?.value
                ? [departmentOptions.selected.value]
                : null,
            },
          })) as ProjectionsResponse;
          const planCurrencyProjections =
            planProjectionResponse.data.data.currencyProjections;

          const planHeadcountProjection =
            planProjectionResponse.data.data.headcountProjections;

          planProjectionForSelectedView =
            format === "expenses"
              ? planCurrencyProjections
              : planHeadcountProjection;
        }

        if (actualProjectionsResponse.data.data) {
          const generatedMonths = (): Date[] => {
            const startMonth = startOfYear(startDate);
            const monthsToGenerate =
              differenceInCalendarMonths(endOfYear(startDate), startMonth) + 1;

            const months = Array.from({ length: monthsToGenerate }, (_, i) =>
              addMonths(new Date(startMonth), i),
            );

            return months;
          };
          const generatedQuarters = (): Date[] => {
            const startQuarter = startOfYear(startDate);

            const quartersToGenerate =
              differenceInCalendarQuarters(
                endOfYear(addYears(startDate, 1)),
                startOfYear(startDate),
              ) + 1;

            const quarters = Array.from(
              { length: quartersToGenerate },
              (_, i) => addQuarters(new Date(startQuarter), i),
            );

            return quarters;
          };

          const generatedDates = isQuarterMode
            ? generatedQuarters()
            : generatedMonths();

          const aggregateQuarterlyData = (
            projections: Projection[],
            quarterIndex: number,
          ): number =>
            projections.reduce((totalSum, { groups }) => {
              const returnSum =
                totalSum +
                groups.reduce((groupSum, { values }) => {
                  const quarterValues = values.slice(
                    quarterIndex * 3,
                    quarterIndex * 3 + 3,
                  );
                  return (
                    groupSum +
                    quarterValues.reduce(
                      (sum, value) => sum + (value.value || 0),
                      0,
                    )
                  );
                }, 0);
              return returnSum;
            }, 0);

          const aggregateData = (
            projections: Projection[],
            index: number,
          ): number =>
            projections.reduce((totalSum, { groups }) => {
              const returnValue =
                totalSum +
                groups.reduce((groupSum, { values }) => {
                  const value = values[index]?.value;
                  return groupSum + (value || 0);
                }, 0);
              return returnValue;
            }, 0);

          const formattedData = generatedDates.map((header, index): Datum => {
            let actualSum = 0;
            let planSum: number | null = 0;

            if (isQuarterMode) {
              actualSum = aggregateQuarterlyData(
                [actualProjectionForSelectedView[0]],
                index,
              );
              planSum = planProjectionForSelectedView
                ? aggregateQuarterlyData(
                    [planProjectionForSelectedView[0]],
                    index,
                  )
                : null;
            } else {
              actualSum = aggregateData(
                [actualProjectionForSelectedView[0]],
                index,
              );
              planSum = planProjectionForSelectedView
                ? aggregateData([planProjectionForSelectedView[0]], index)
                : null;
            }

            const formattedDate = formatDate(
              header,
              isQuarterMode ? "QQQ yy" : "MMM yyyy",
            );

            return {
              timeFrame: formattedDate,
              actual: actualSum,
              plan: planSum,
            };
          });

          setData(formattedData);
        }
        setIsLoading(false);
      } catch (error) {
        if (error.name !== "CanceledError") {
          setIsLoading(false);
        }
      }
    };
    if (!pageIsLoading) {
      getData();
    }
  }, [
    pageIsLoading,
    format,
    planToCompare,
    periodDates,
    departmentOptions,
    organizationUuid,
    isQuarterMode,
    startDate,
  ]);

  /**
   *This useEffect allows the Skeleton to continue loading if a planToCompare is selected
   */
  useEffect(() => {
    if (planToCompare) {
      if (data.some(({ actual, plan }) => actual !== null && plan !== null))
        setIsLoading(false);
    } else if (data.some(({ actual }) => actual !== null)) {
      setIsLoading(false);
    }
  }, [data, planToCompare]);

  const dataFormatter = (number: number): string => {
    if (format === "headcount") return `${number}`;

    if (number > 100000000000) return `$${(number / 100000000000).toString()}B`;
    if (number > 100000000) return `$${(number / 100000000).toString()}M`;
    if (number > 100000) return `$${(number / 100000).toString()}K`;
    return `$${number.toString()}`;
  };

  return (
    <Card>
      <Typography color="subHead" size="lg" weight="medium" className="mb-2">
        Analytics
      </Typography>
      <div className="w-full" data-testid="analytics-card-wrapper">
        <div className="flex justify-end w-full">
          <div>
            <SegmentedControl
              name="headcount-expenses-view"
              value={format}
              setValue={(val: string) =>
                setFormat(val as "headcount" | "expenses")
              }
              segments={[
                {
                  label: "Expenses",
                  value: "expenses",
                },
                {
                  label: "Headcount",
                  value: "headcount",
                },
              ]}
            />
          </div>
        </div>
        <div className="w-full h-[300px] md:-mt-10">
          <ResponsiveContainer width="100%" height="100%">
            {isLoading ? (
              <div className="ml-1 flex flex-col w-full h-full">
                <div className="m-[4.5px]">
                  <LegendContent
                    payload={[
                      { value: "Planned Spend", color: "#95AFBB" },
                      { value: "Actual Spend", color: "#5A8496" },
                    ]}
                  />
                </div>
                <div className="flex flex-row w-full h-full">
                  <div className="flex flex-col gap-7 mt-3 w-[54px] h-[230px] ">
                    {Array.from({ length: 5 }).map((_, index) => (
                      <Skeleton
                        key={
                          /* eslint-disable-next-line react/no-array-index-key */
                          `y-axis-skeleton-${index}`
                        }
                        height={16}
                        width={42}
                        baseColor="green" /* ...other props */
                      />
                    ))}
                  </div>
                  <div className="flex flex-col gap-4 ml-2 w-full h-full justify-end">
                    <div className="flex flex-row w-full justify-around">
                      {Array.from({ length: 12 }).map((_, index) => (
                        <Skeleton
                          key={
                            /* eslint-disable-next-line react/no-array-index-key */
                            `bar-skeleton-${index}`
                          }
                          height={200}
                          width={24}
                          baseColor="green" /* ...other props for bars */
                        />
                      ))}
                    </div>
                    <div className="flex flex-row w-full justify-around">
                      {Array.from({ length: 12 }).map((_, index) => (
                        <Skeleton
                          key={
                            /* eslint-disable-next-line react/no-array-index-key */
                            `x-axis-skeleton-${index}`
                          }
                          height={16}
                          width={32}
                          baseColor="green" /* ...other props */
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <BarChart
                data={data}
                barGap={0}
                barCategoryGap={windowSize?.width ? windowSize.width / 90 : 20}
                barSize={16}
              >
                <CartesianGrid vertical={false} stroke="#F2F2F2" />
                <XAxis
                  dataKey="timeFrame"
                  dy={10}
                  tickLine={false}
                  tickFormatter={(label: string) =>
                    isQuarterMode ? label : label.substring(0, 3)
                  }
                  tick={{ fill: "#7C7C7A" }}
                  stroke="#F2F2F2"
                />
                <YAxis
                  tickLine={false}
                  tickFormatter={dataFormatter}
                  tick={{
                    fill: "#7C7C7A",
                  }}
                  stroke="none"
                />
                <Legend
                  align="left"
                  verticalAlign="top"
                  height={50}
                  iconType="circle"
                  iconSize={10}
                  content={<LegendContent />}
                />
                <Tooltip
                  labelFormatter={(label: string) => {
                    const [timeFrame] = label.split(" ");
                    return (
                      timeFrame.charAt(0).toUpperCase() + timeFrame.slice(1)
                    );
                  }}
                  content={({ payload }) => (
                    <TooltipContent payload={payload} format={format} />
                  )}
                />
                <Bar name="Planned Spend" dataKey="plan" fill="#93A7B0">
                  {data.map((entry) => (
                    <Cell
                      key={`plan-${entry.timeFrame}`}
                      fill={
                        isBefore(
                          parse(
                            entry.timeFrame,
                            isQuarterMode ? "QQQ yy" : "MMM yyyy",
                            date().toDate(),
                          ),
                          startOfMonth(date().toDate()),
                        )
                          ? "#C9C9C9"
                          : "#95ADBB"
                      }
                    />
                  ))}
                </Bar>
                <Bar name="Actual Spend" dataKey="actual" fill="#003049">
                  {data.map((entry) => (
                    <Cell
                      key={`actual-${entry.timeFrame}`}
                      fill={
                        isBefore(
                          parse(
                            entry.timeFrame,
                            isQuarterMode ? "QQQ yy" : "MMM yyyy",
                            date().toDate(),
                          ),
                          startOfMonth(date().toDate()),
                        )
                          ? "#999999"
                          : "#5A8496"
                      }
                    />
                  ))}
                </Bar>
              </BarChart>
            )}
          </ResponsiveContainer>
        </div>
      </div>
    </Card>
  );
};
export default AnalyticsCard;
