import React, { useEffect, useState } from "react";
import Card from "~/components/Card";
import request from "~/utils/request";
import { useSelector } from "react-redux";
import { State } from "~/store";
import useInput from "~/components/Input/useInput";
import Button from "~/components/Button";
import Input from "~/components/Input";
import Select, { useSelect } from "~/components/Select";
import Typography from "~/components/Typography";
import DepartmentListItem, {
  IDepartment,
  DepartmentHierarchy,
} from "./DepartmentListItem";

interface DepartmentResponse {
  data: {
    data: IDepartment[];
  } | null;
  status: number;
}

const GroupsContainer = (): React.ReactNode => {
  const { uuid: organizationUuid } = useSelector(
    (state: State) => state.organization,
  );
  const [addDepartment, setAddDepartment] = useState(false);
  const [newDepartmentName, setNewDepartmentName] = useInput({});

  const parentDefault = {
    label: "No Parent",
    value: "none",
  };
  const [newDepartmentParent, setNewDepartmentParent] = useSelect({
    options: [parentDefault],
    selected: parentDefault,
  });
  const [departments, setDepartments] = useState<DepartmentHierarchy[]>([]);
  const [flatDepartments, setFlatDepartments] = useState<IDepartment[]>([]);

  const getDepartments = async (): Promise<void> => {
    const fetchDepartmentsResponse: DepartmentResponse = await request({
      url: `/organizations/${organizationUuid}/groups`,
      method: "GET",
    });
    if (fetchDepartmentsResponse.status >= 400) return;
    if (fetchDepartmentsResponse.data) {
      const { data } = fetchDepartmentsResponse.data;

      setNewDepartmentParent({
        ...newDepartmentParent,
        options: [
          parentDefault,
          ...data.map((department) => ({
            label: department.name,
            value: department.uuid,
          })),
        ],
        selected: parentDefault,
      });
      const departmentDict = data.reduce(
        (output: Record<string, IDepartment>, department) => {
          const modifiedOutput = output;
          modifiedOutput[department.uuid] = department;
          return modifiedOutput;
        },
        {},
      );

      const rootDepartments: DepartmentHierarchy[] = [];

      const buildHierarchy = (department: IDepartment): DepartmentHierarchy => {
        const childDepartments: IDepartment[] = [];

        // eslint-disable-next-line guard-for-in,no-restricted-syntax
        for (const departmentUuid in departmentDict) {
          const childDepartment = departmentDict[departmentUuid];
          if (childDepartment.parentUuid === department.uuid) {
            childDepartments.push(childDepartment);
          }
        }

        return {
          uuid: department.uuid,
          name: department.name,
          parentUuid: department.parentUuid ?? parentDefault.value,
          departments: childDepartments.map(buildHierarchy),
        };
      };

      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const departmentUuid in departmentDict) {
        const department = departmentDict[departmentUuid];
        if (department.parentUuid === null) {
          rootDepartments.push(buildHierarchy(department));
        }
      }

      setDepartments(rootDepartments);
      setFlatDepartments(data);
    }
  };

  useEffect(() => {
    getDepartments();
  }, []);

  const attemptAddDepartment = async (): Promise<void> => {
    if (newDepartmentName.valid && newDepartmentParent.valid) {
      const selection = newDepartmentParent.selected?.value;
      await request({
        url: `/organizations/${organizationUuid}/groups`,
        method: "POST",
        body: {
          name: newDepartmentName.value,
          parentUuid: selection !== parentDefault.value ? selection : null,
        },
      });
      setNewDepartmentName({
        ...newDepartmentName,
        value: "",
      });
      setNewDepartmentParent({
        ...newDepartmentParent,
        selected: parentDefault,
      });
      setAddDepartment(false);
      getDepartments();
    } else {
      setNewDepartmentName({
        ...newDepartmentName,
        pristine: false,
        touched: true,
      });
      setNewDepartmentParent({
        ...newDepartmentParent,
        pristine: false,
        touched: true,
      });
    }
  };

  return (
    <Card className="w-full flex flex-col gap-5">
      <div className="flex flex-row">
        <div className="flex flex-col md:w-[70%]">
          <Typography size="sm" weight="semibold">
            Departments
          </Typography>
          <Typography color="secondary" size="xs">
            Organize and create departments and nested departments here to be
            able to drill down in your projections.
          </Typography>
        </div>
      </div>
      <div className="flex flex-col w-full gap-5">
        {departments.map((department) => (
          <DepartmentListItem
            key={department.uuid}
            department={department}
            allDepartments={flatDepartments}
            onUpdate={getDepartments}
          />
        ))}
        {!addDepartment && (
          <Button
            fill="outline"
            className="!w-fit !px-12"
            onClick={() => setAddDepartment(true)}
          >
            Add department
          </Button>
        )}
      </div>
      {addDepartment && (
        <div className="flex flex-col w-full mt-5">
          <h2 className="text-xl font-bold mb-2">Add a new department</h2>
          <Input
            label="Department name"
            className="w-full"
            state={newDepartmentName}
            setState={setNewDepartmentName}
            id="department-name"
            placeholder="Enter department name"
          />
          <Select
            id="department-parent"
            label="Parent Department"
            className="w-full"
            state={newDepartmentParent}
            setState={setNewDepartmentParent}
          />
          <Button className="mt-3" onClick={attemptAddDepartment}>
            Add
          </Button>
          <Button
            className="mt-3"
            fill="outline"
            onClick={() => {
              setNewDepartmentName({
                ...newDepartmentName,
                value: "",
              });
              setNewDepartmentParent({
                ...newDepartmentParent,
                selected: parentDefault,
              });
              setAddDepartment(false);
            }}
          >
            Cancel
          </Button>
        </div>
      )}
    </Card>
  );
};

export default GroupsContainer;
