import React, { useCallback, useEffect, useMemo } from "react";
import Combobox from "~/components/Combobox/Combobox";
import useCombobox from "~/components/Combobox/useCombobox";
import request from "~/utils/request";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { State } from "~/store";
import parallelIcon from "../../assets/logos/logo.svg";

const ParallelDevTools = () => {
  const { isLoggedIn } = useSelector((state: State) => state.auth);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isVisible, setIsVisible] = React.useState(false);
  const searchStateOptions = useMemo(
    () => [
      ...(isLoggedIn
        ? [
            {
              label: "Reset my data",
              value: "reset-my-data",
            },
          ]
        : []),
    ],
    [isLoggedIn],
  );
  const [searchState, setSearchState] = useCombobox({
    options: searchStateOptions,
  });

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check if Control key and 'p' key are pressed
      if (event.ctrlKey && event.key === "p") {
        // Update the state when Control + P is pressed
        setIsVisible((prevState) => !prevState);
      } else if (event.key === "Escape") {
        setIsVisible(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []); // Empty dependency array means this effect runs once on mount

  useEffect(() => {
    if (isVisible) {
      const devToolsContainer = document.getElementById("devTools");
      const inputElement = devToolsContainer?.querySelector("input");
      if (inputElement) inputElement.focus();
    }
  }, [isVisible]);

  const resetData = useCallback(async (endpoint: string) => {
    try {
      setSearchState((prevState) => ({
        ...prevState,
        query: "",
        selected: { label: null, value: null },
      }));
      setIsLoading(true);
      const { status } = await request({
        url: `/dev/${endpoint}`,
        method: "POST",
      });
      if (status === 200) {
        toast.success("Data reset successfully");
      } else {
        toast.error("Unable to reset data");
      }
    } catch (err) {
      toast.error("Unable to reset data");
    } finally {
      setIsVisible(false);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    const task = searchState.selected?.value;
    if (task === "reset-my-data") {
      resetData("reset-data");
    }
  }, [searchState.selected]);

  /**
   * Update the options when the user logs in or out
   */
  useEffect(() => {
    setSearchState((prevState) => ({
      ...prevState,
      options: searchStateOptions,
    }));
  }, [isLoggedIn]);

  return (
    <div
      id="devTools"
      className={`absolute flex flex-col w-full h-screen pt-[30vh] z-50 items-center bg-black bg-opacity-60 ${
        isVisible ? "" : "hidden"
      }`}
    >
      <Combobox
        disabled={isLoading}
        id="devToolsSearch"
        state={searchState}
        setState={setSearchState}
        className="!w-auto"
        inputClassName="h-[75px] w-[500px] md:text-xl"
        placeholder={!isLoading ? "Search for a task" : "Loading..."}
        leftIcon={
          <img
            className="w-8 h-8"
            alt="parallel logo icon"
            src={parallelIcon}
          />
        }
      />
    </div>
  );
};

export default ParallelDevTools;
