import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

import authReducer, { AuthState } from "./authSlice";
import organizationReducer, { OrganizationState } from "./organizationSlice";
import userReducer, { UserState } from "./userSlice";
import scenarioReducer, { ScenarioState } from "./scenarioSlice";
import settingsReducer, { SettingsState } from "./settingsSlice";
import tasksReducer, { TasksState } from "./tasksSlice";

export interface State {
  auth: AuthState;
  user: UserState;
  organization: OrganizationState;
  scenario: ScenarioState;
  settings: SettingsState;
  tasks: TasksState;
}

const reducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  organization: organizationReducer,
  scenario: scenarioReducer,
  settings: settingsReducer,
  tasks: tasksReducer,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "auth",
    "user",
    "positions",
    "organization",
    "scenario",
    "settings",
    "tasks",
  ],
  blacklist: [],
};

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);
