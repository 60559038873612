import React from "react";
import Card from "~/components/Card";
import Typography from "~/components/Typography";
import Skeleton from "~/components/Skeleton";
import { useFeatureFlag } from "@harnessio/ff-react-client-sdk";
import { format } from "date-fns";
import isDateBetweenDatesInclusive from "~/utils/dates/isDateBetweenDatesInclusive";

export interface Props {
  isLoading: boolean;
  data?: IHeadcount;
  periodDates: {
    mode: "month" | "quarter" | "year";
    startDate: Date;
    endDate: Date;
  };
}

export interface IHeadcountTotals {
  beginning: number;
  newHires: number;
  terminations: number;
  ending: number;
}

export interface IHeadcount {
  currentWorkingModelHeadcount: IHeadcountTotals;
  plannedHeadcount: IHeadcountTotals;
  currentHeadcountTotal: number;
}

const HeadcountCard = ({
  isLoading,
  data,
  periodDates,
}: Props): React.ReactNode => {
  const headcountTotalParity = Boolean(useFeatureFlag("headcountTotalParity"));

  return (
    <Card>
      {!headcountTotalParity ? (
        <>
          <div className="flex justify-between items-center w-full">
            <Typography tag="h3" color="subHead" size="lg" weight="medium">
              Headcount
            </Typography>
          </div>
          <div>
            {!isLoading && data ? (
              <p
                className="text-[2.5rem] py-2"
                data-testid="headcount-summary-total"
              >
                {data.currentWorkingModelHeadcount.ending}
              </p>
            ) : (
              <Skeleton
                baseColor="green"
                className="my-[25px]"
                height={26}
                width={148}
              />
            )}
          </div>
          <div className="bg-green-15 p-5 rounded-2xl w-full flex flex-col gap-1">
            <div className="flex justify-between">
              <div className="flex flex-row gap-2">
                <Typography>Beginning</Typography>
                <Typography color="empty" size="sm">
                  {format(periodDates.startDate, "MMM dd")}
                </Typography>
              </div>
              {!isLoading && data ? (
                <p
                  className="text-end font-thin"
                  data-testid="headcount-summary-beginning-of-period"
                >
                  {data.currentWorkingModelHeadcount.beginning}
                </p>
              ) : (
                <Skeleton
                  baseColor="white"
                  className="my-0.5"
                  height={16}
                  width={108}
                />
              )}
            </div>
            <div className="flex justify-between">
              <p className="text-start font-thin">Hired</p>
              {!isLoading && data ? (
                <p
                  className="text-end font-thin"
                  data-testid="headcount-summary-hires-during-period"
                >
                  {data.currentWorkingModelHeadcount.newHires}
                </p>
              ) : (
                <Skeleton
                  baseColor="white"
                  className="my-0.5"
                  height={16}
                  width={108}
                />
              )}
            </div>
            <div className="flex justify-between pb-1">
              <p className="text-start font-thin">Terminated</p>
              {!isLoading && data ? (
                <p
                  className="text-end font-thin"
                  data-testid="headcount-summary-terminations"
                >
                  {data.currentWorkingModelHeadcount.terminations}
                </p>
              ) : (
                <Skeleton
                  baseColor="white"
                  className="my-0.5"
                  height={16}
                  width={108}
                />
              )}
            </div>
            <div className="flex justify-between border-t pt-1">
              <div className="flex flex-row gap-2">
                <Typography>Ending</Typography>
                <Typography color="empty" size="sm">
                  {format(periodDates.endDate, "MMM dd")}
                </Typography>
              </div>
              {!isLoading && data ? (
                <p
                  className="text-end font-thin"
                  data-testid="headcount-summary-total-math"
                >
                  {data.currentWorkingModelHeadcount.ending}
                </p>
              ) : (
                <Skeleton
                  baseColor="white"
                  className="my-0.5"
                  height={16}
                  width={108}
                />
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="flex justify-between items-center w-full">
            <Typography tag="h3" color="subHead" size="lg" weight="medium">
              Headcount
            </Typography>
          </div>
          <div className="w-full flex justify-between">
            {!isLoading && data ? (
              isDateBetweenDatesInclusive({
                comparisonDate: new Date(),
                dateForBefore: periodDates.startDate,
                dateForAfter: periodDates.endDate,
              }) ? (
                <div className="flex flex-row gap-2 items-center">
                  <p
                    className="text-[2.5rem] py-2"
                    data-testid="headcount-summary-total"
                  >
                    {data.currentHeadcountTotal}
                  </p>
                  <Typography
                    size="sm"
                    color="empty"
                    className="w-[46px] leading-5"
                  >
                    as of today
                  </Typography>
                </div>
              ) : (
                <p
                  className="text-[2.5rem] py-2"
                  data-testid="headcount-summary-total"
                >
                  {data.currentWorkingModelHeadcount.ending}
                </p>
              )
            ) : (
              <Skeleton
                baseColor="green"
                className="my-[25px]"
                height={26}
                width={148}
              />
            )}
            <div className="flex flex-row gap-10 mr-5 items-end mb-2">
              <Typography
                size="sm"
                color={
                  data?.plannedHeadcount ?? isLoading ? undefined : "empty"
                }
                className="w-[60px] text-right"
              >
                Budget
              </Typography>
              <Typography size="sm" className="w-[60px] text-right">
                CWM
              </Typography>
            </div>
          </div>
          <div className="bg-green-15 p-5 rounded-2xl w-full flex flex-col gap-1">
            <div className="flex justify-between">
              <div className="flex flex-row gap-2">
                <Typography>Beginning</Typography>
                <Typography color="empty" size="sm">
                  {format(periodDates.startDate, "MMM dd")}
                </Typography>
              </div>
              <div className="flex flex-row gap-10">
                {!isLoading ? (
                  data?.plannedHeadcount ? (
                    <p
                      className="text-end font-thin w-[60px]"
                      data-testid="planned-headcount-summary-beginning-of-period"
                    >
                      {data.plannedHeadcount.beginning}
                    </p>
                  ) : (
                    <Typography
                      size="sm"
                      color="empty"
                      className="w-[60px] text-end"
                    >
                      -
                    </Typography>
                  )
                ) : (
                  <Skeleton
                    baseColor="white"
                    className="my-0.5"
                    height={16}
                    width={60}
                  />
                )}
                {!isLoading && data ? (
                  <p
                    className="text-end font-thin w-[60px]"
                    data-testid="headcount-summary-beginning-of-period"
                  >
                    {data.currentWorkingModelHeadcount.beginning}
                  </p>
                ) : (
                  <Skeleton
                    baseColor="white"
                    className="my-0.5"
                    height={16}
                    width={60}
                  />
                )}
              </div>
            </div>
            <div className="flex justify-between">
              <p className="text-start">Hired</p>
              <div className="flex flex-row gap-10">
                {!isLoading ? (
                  data?.plannedHeadcount ? (
                    <p
                      className="text-end font-thin w-[60px]"
                      data-testid="planned-headcount-summary-hires-during-period"
                    >
                      {data.plannedHeadcount.newHires}
                    </p>
                  ) : (
                    <Typography
                      size="sm"
                      color="empty"
                      className="w-[60px] text-end"
                    >
                      -
                    </Typography>
                  )
                ) : (
                  <Skeleton
                    baseColor="white"
                    className="my-0.5"
                    height={16}
                    width={60}
                  />
                )}
                {!isLoading && data ? (
                  <p
                    className="text-end font-thin w-[60px]"
                    data-testid="headcount-summary-hires-during-period"
                  >
                    {data.currentWorkingModelHeadcount.newHires}
                  </p>
                ) : (
                  <Skeleton
                    baseColor="white"
                    className="my-0.5"
                    height={16}
                    width={60}
                  />
                )}
              </div>
            </div>
            <div className="flex justify-between pb-1">
              <p className="text-start">Terminated</p>
              <div className="flex flex-row gap-10">
                {!isLoading ? (
                  data?.plannedHeadcount ? (
                    <p
                      className="text-end font-thin w-[60px]"
                      data-testid="planned-headcount-summary-terminations"
                    >
                      {data.plannedHeadcount.terminations}
                    </p>
                  ) : (
                    <Typography
                      size="sm"
                      color="empty"
                      className="w-[60px] text-end"
                    >
                      -
                    </Typography>
                  )
                ) : (
                  <Skeleton
                    baseColor="white"
                    className="my-0.5"
                    height={16}
                    width={60}
                  />
                )}
                {!isLoading && data ? (
                  <p
                    className="text-end font-thin w-[60px]"
                    data-testid="headcount-summary-terminations"
                  >
                    {data.currentWorkingModelHeadcount.terminations}
                  </p>
                ) : (
                  <Skeleton
                    baseColor="white"
                    className="my-0.5"
                    height={16}
                    width={60}
                  />
                )}
              </div>
            </div>
            <div className="flex justify-between border-t pt-1">
              <div className="flex flex-row gap-2">
                <Typography>Ending</Typography>
                <Typography color="empty" size="sm">
                  {format(periodDates.endDate, "MMM dd")}
                </Typography>
              </div>
              <div className="flex flex-row gap-10">
                {!isLoading ? (
                  data?.plannedHeadcount ? (
                    <p
                      className="text-end font-thin w-[60px]"
                      data-testid="planned-headcount-summary-total-math"
                    >
                      {data.plannedHeadcount.ending}
                    </p>
                  ) : (
                    <Typography
                      size="sm"
                      color="empty"
                      className="w-[60px] text-end"
                    >
                      -
                    </Typography>
                  )
                ) : (
                  <Skeleton
                    baseColor="white"
                    className="my-0.5"
                    height={16}
                    width={60}
                  />
                )}
                {!isLoading && data ? (
                  <p
                    className="text-end font-thin w-[60px]"
                    data-testid="headcount-summary-total-math"
                  >
                    {data.currentWorkingModelHeadcount.ending}
                  </p>
                ) : (
                  <Skeleton
                    baseColor="white"
                    className="my-0.5"
                    height={16}
                    width={60}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </Card>
  );
};

export default HeadcountCard;
