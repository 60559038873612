import React from "react";
import Typography from "~/components/Typography";
import {
  ICompCalcDrilldownDetails,
  IDrilldownPosition,
} from "./useDrilldownDrawer";
import { formatInTimeZone } from "date-fns-tz";
import displayEmploymentType from "~/utils/displayEmploymentType";
import { Link } from "react-router-dom";
import formatCurrency from "~/utils/formatCurrency";

export interface CalculationData {
  impact: number;
  calculations: ICompCalcDrilldownDetails[];
  employeeName: string;
  employeeNumber: string;
  positions: IDrilldownPosition[];
}

const getDrilldownTableValues = ({
  title,
  header,
  employeeName,
  employeeNumber,
  positions,
  impact,
  calculations,
  handleClickView,
  formatCents,
}: {
  title?: string;
  header: string;
  employeeName?: string;
  employeeNumber?: string;
  positions: IDrilldownPosition[];
  impact?: number;
  calculations?: ICompCalcDrilldownDetails[];
  handleClickView: (userData: CalculationData) => void;
  formatCents?: boolean;
}): React.ReactNode => {
  switch (true) {
    case header.includes("EMPLOYEE"): {
      return (
        <div className="flex flex-col">
          <Typography className="truncate ">{employeeName ?? "-"}</Typography>
          <Typography color="empty" size="2xs" className="truncate">
            {employeeNumber}
          </Typography>
        </div>
      );
    }
    case header.includes("POSITION"): {
      return (
        <div className="flex flex-col">
          {positions.map((position) => (
            <Link
              to={`/positions/${position.positionUuid}`}
              key={`${title}-${position.positionUuid}`}
              className="flex flex-col"
            >
              <Typography className="truncate hover:underline hover:text-green-500 ">
                {position.title}
              </Typography>
              <Typography color="empty" size="2xs" className="truncate">
                {displayEmploymentType(position.employmentType)}
              </Typography>
            </Link>
          ))}
        </div>
      );
    }
    case header.includes("DATE"): {
      return (
        <div>
          {positions.map(
            (position) =>
              position.effectiveAt && (
                <div key={`${title}-${position.positionUuid}-${employeeName}`}>
                  <Typography
                    className={`${
                      header.includes("START") ? "" : "text-red-400"
                    } truncate`}
                  >
                    {formatInTimeZone(
                      new Date(position.effectiveAt),
                      "UTC",
                      "MM/dd/yyyy",
                    )}
                  </Typography>
                </div>
              ),
          )}
        </div>
      );
    }
    case header.includes("CALC") && !!impact && !!calculations: {
      return (
        <div
          onClick={() =>
            handleClickView({
              employeeName: employeeName ?? "",
              employeeNumber: employeeNumber ?? "",
              impact,
              calculations,
              positions,
            })
          }
        >
          <Typography color="green" className="underline cursor-pointer ">
            View
          </Typography>
        </div>
      );
    }
    case header.includes("MO. IMPACT") && !!impact: {
      return (
        <div className="flex items-start justify-end">
          <Typography className="truncate">
            {formatCurrency(
              impact,
              formatCents && impact <= 2000 ? true : false,
            )}
          </Typography>
        </div>
      );
    }
    case header.includes("DEPARTMENT"): {
      return (
        <div className="flex">
          {positions.map((position) => (
            <Typography
              className="truncate"
              key={`${title}-${position.departmentUuid}-${position.positionUuid}`}
            >
              {position.departmentName}
            </Typography>
          ))}
        </div>
      );
    }
    case header === "":
    default: {
      return <div />;
    }
  }
};

export default getDrilldownTableValues;
