import Header from "~/components/Header";
import SegmentedControl from "~/components/SegmentedControl";
import Table, { TableData } from "~/components/Table";
import { State } from "~/store";
import formatCurrency from "~/utils/formatCurrency";
import request from "~/utils/request";
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import date from "~/utils/dates/date";
import { Employee, Position } from "pages/Headcount/headcount.types";
import Select, { useSelect } from "~/components/Select";
import { PlansResponse } from "pages/Dashboard";
import emptyVarianceStateIllustration from "~/assets/emptyVarianceStateIllustration.svg";
import Typography from "~/components/Typography";
import DateRange from "~/components/DateRange";
import usePeriodPicker from "~/components/PeriodPicker/usePeriodPicker";
import { endOfMonth, format, startOfMonth } from "date-fns";
import ExportData from "~/components/ExportData";
import formatItemizedVariancesForCSVExport from "~/utils/formatItemizedVariancesForCSVExport";
import formatDepartmentVariancesForCSVExport from "~/utils/formatDepartmentVariancesForCSVExport";
import BudgetCard from "../Dashboard/BudgetCard";

type SelectedViewType = "itemized" | "department";

export interface GroupsResponse {
  data: {
    data?: Types.Group[];
  };
}

interface VariancesResponse {
  status: number;
  data: {
    data?: {
      variances: VarianceData[];
    };
  };
}

interface VarianceData {
  groupUuid: string;
  groupName: string;
  variances: SingleVariance[];
  totalUniqueRoles: number;
  totalImpact: number;
}

interface SingleVariance {
  variantType: "expense" | "position";
  uuid: string;
  variantA?: Position["current"] | undefined;
  variantB?: Position["current"] | undefined;
  amount: number;
  modification: string;
  name: string;
  title: string;
  employee: Employee | null | undefined;
  effectiveDate: string;
}

const VariancesContainer = (): React.ReactNode => {
  /**
   * Store
   */
  const { uuid: organizationUuid } = useSelector(
    (state: State) => state.organization,
  );
  /**
   * Component state
   */
  const controllerRef = useRef<AbortController>();
  const planControllerRef = useRef<AbortController>();
  const [isLoading, setIsLoading] = useState(true);

  const [searchParams, setSearchParams] = useSearchParams();
  const initialPlanUuid = searchParams.get("planUuid") ?? "";
  const initialMode = searchParams.get("mode") ?? "month";
  const initialStartDate = searchParams.get("startDate")
    ? date(searchParams.get("startDate") as string).toDate()
    : startOfMonth(date().toDate());
  const initialEndDate = searchParams.get("endDate")
    ? date(searchParams.get("endDate") as string).toDate()
    : endOfMonth(date().toDate());

  const [periodDates, setPeriodDates] = usePeriodPicker({
    startDate: initialStartDate,
    endDate: initialEndDate,
    mode: initialMode as "month" | "quarter" | "year",
  });

  const [planOptions, setPlanOptions] = useSelect({
    options: [],
    selected: { value: initialPlanUuid, label: "" },
    isLoading: true,
  });
  const [pageData, setPageData] = useState<{
    roleVariance: TableData[];
    departmentVariance: TableData[];
  }>();
  const [selectedView, setSelectedView] =
    useState<SelectedViewType>("itemized");
  const [departmentOptions, setDepartmentOptions] = useSelect({
    options: [{ value: null, label: "All Departments" }],
    selected: { value: null, label: "All Departments" },
  });

  const fetchDepartments = async (): Promise<void> => {
    try {
      const groupsResponse = (await request({
        url: `/organizations/${organizationUuid}/groups`,
        method: "GET",
        params: {
          topLevel: true,
        },
      })) as GroupsResponse;
      if (groupsResponse.data.data) {
        const options = [
          { value: null, label: "All Departments" },
          ...groupsResponse.data.data.map((group) => ({
            label: group.name,
            value: group.uuid,
          })),
        ];
        setDepartmentOptions((prevState) => ({
          ...prevState,
          selected: { value: null, label: "All Departments" },
          options,
        }));
      }
    } catch (error: unknown) {
      console.error("Error fetching departments:", error);
    }
  };

  const fetchPlans = async (): Promise<void> => {
    try {
      if (planControllerRef.current) planControllerRef.current.abort();
      planControllerRef.current = new AbortController();
      const { signal } = planControllerRef.current;
      const fetchPlansResponse = (await request({
        url: `/organizations/${organizationUuid}/plans`,
        method: "GET",
        signal,
      })) as PlansResponse;
      if (fetchPlansResponse.data.data.length > 0) {
        const options = fetchPlansResponse.data.data.map((plan) => ({
          label: plan.label,
          value: plan.uuid,
        }));

        const { label } = options.find(
          (option) => option.value === searchParams.get("planUuid"),
        ) ?? { label: "" };
        setPlanOptions((prevState) => ({
          ...prevState,
          selected: { value: searchParams.get("planUuid") ?? "", label },
          options,
          isLoading: false,
        }));
      } else {
        setPlanOptions((prevState) => ({
          ...prevState,
          selected: { value: null, label: "No Locked Plans" },
          isLoading: false,
        }));
      }
    } catch (error: unknown) {
      if (error.name !== "CanceledError") {
        setPlanOptions((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      }
    }
  };

  useEffect(() => {
    if (organizationUuid) {
      fetchDepartments();
      fetchPlans();
    }
  }, [organizationUuid]);

  useEffect(() => {
    const currentPlanUuid = searchParams.get("planUuid");
    const currentMode = searchParams.get("mode");
    const currentStartDate = searchParams.get("startDate");
    const currentEndDate = searchParams.get("endDate");

    if (
      planOptions.selected?.value !== currentPlanUuid ||
      periodDates.mode !== currentMode ||
      format(periodDates.startDate, "yyyy-MM-dd") !== currentStartDate ||
      format(periodDates.endDate, "yyyy-MM-dd") !== currentEndDate
    ) {
      setSearchParams({
        planUuid: planOptions.selected?.value ?? "",
        mode: periodDates.mode,
        startDate: format(periodDates.startDate, "yyyy-MM-dd"),
        endDate: format(periodDates.endDate, "yyyy-MM-dd"),
      });
    }
  }, [periodDates, planOptions.selected, searchParams]);

  const buildRoleVarianceData = (
    departmentVariances: VarianceData[],
  ): TableData[] => {
    let totalImpact = 0;
    const tableData = departmentVariances.flatMap(({ groupName, variances }) =>
      variances.map((variance) => {
        totalImpact += variance.amount;

        return {
          id: (variance.variantA?.uuid ?? variance.variantB?.uuid) as string,
          exportLabel: groupName,
          values: [
            { value: variance.employee?.employeeNumber ?? "-" },
            { value: variance.name || "-" },
            { value: variance.title || "-" },
            { value: groupName },
            { value: variance.modification },
            { value: date(variance.effectiveDate).format("MM/DD/YY") },
            { value: formatCurrency(variance.amount, false) },
          ],
        };
      }),
    );
    if (tableData.length === 0) {
      return [...tableData];
    }
    return [
      ...tableData,
      {
        id: "totals",
        exportLabel: "Total",
        values: [
          { value: "Total" },
          { value: "" },
          { value: "" },
          { value: "" },
          { value: "" },
          { value: "" },
          { value: formatCurrency(totalImpact, false) },
        ],
      },
    ];
  };

  const buildDepartmentVarianceData = (
    variances: VarianceData[],
  ): TableData[] => {
    let totalOrgUniqueRoles = 0;
    let totalOrgImpact = 0;
    const tableData = variances.map((departmentVariance) => {
      totalOrgUniqueRoles += departmentVariance.totalUniqueRoles;
      totalOrgImpact += departmentVariance.totalImpact;
      return {
        id: departmentVariance.groupUuid,
        values: [
          { value: departmentVariance.groupName },
          { value: departmentVariance.totalUniqueRoles },
          { value: formatCurrency(departmentVariance.totalImpact, false) },
        ],
      };
    });
    return [
      ...tableData,
      {
        id: "totals",
        exportLabel: "Total",
        values: [
          {
            value: "Total",
          },
          { value: totalOrgUniqueRoles },
          { value: formatCurrency(totalOrgImpact, false) },
        ],
      },
    ];
  };

  useEffect(() => {
    const getVariances = async (): Promise<void> => {
      try {
        if (controllerRef.current) controllerRef.current.abort();
        controllerRef.current = new AbortController();
        const { signal } = controllerRef.current;
        setIsLoading(true);
        const variancesResponse = (await request({
          url: `/organizations/${organizationUuid}/variances`,
          method: "GET",
          params: {
            planUuid: planOptions.selected?.value,
            startDate: format(periodDates.startDate, "yyyy-MM-dd"),
            endDate: format(periodDates.endDate, "yyyy-MM-dd"),
            departments: departmentOptions.selected?.value
              ? [departmentOptions.selected.value]
              : undefined,
          },
          signal,
        })) as VariancesResponse;

        if (variancesResponse.status === 200 && variancesResponse.data.data) {
          const rawVarianceData = variancesResponse.data.data.variances;
          setPageData({
            roleVariance: buildRoleVarianceData(rawVarianceData),
            departmentVariance: buildDepartmentVarianceData(rawVarianceData),
          });
          if (rawVarianceData.length !== 0) {
            setIsLoading(false);
          }
        }
      } catch (error: unknown) {
        if (error.name !== "CanceledError") {
          setIsLoading(false);
          console.log("Error fetching variances:", error);
        }
      }
    };

    if (
      planOptions.options.length > 0 &&
      departmentOptions.options.length > 1 &&
      departmentOptions.selected &&
      planOptions.selected
    ) {
      getVariances();
    } else if (!planOptions.isLoading) {
      setIsLoading(false);
    }
  }, [
    organizationUuid,
    departmentOptions.options.length,
    planOptions.options.length,
    planOptions.selected,
    periodDates.startDate,
    periodDates.endDate,
    departmentOptions.selected,
    planOptions.isLoading,
  ]);

  const csvToExport =
    selectedView === "itemized"
      ? (formatItemizedVariancesForCSVExport(pageData?.roleVariance).reduce(
          (output, position, index) => {
            if (index === 0) {
              output.push(Object.keys(position));
            }
            output.push(Object.values(position));
            return output;
          },
          [] as (string | number | boolean | React.ReactNode)[],
        ) as unknown[][])
      : (formatDepartmentVariancesForCSVExport(
          pageData?.departmentVariance,
        ).reduce(
          (output, position, index) => {
            if (index === 0) {
              output.push(Object.keys(position));
            }
            output.push(Object.values(position));
            return output;
          },
          [] as (string | number | boolean | React.ReactNode)[],
        ) as unknown[][]);

  return (
    <div>
      <Header
        title="Variances"
        breadCrumbs={[{ label: "Dashboard", url: "/dashboard" }]}
      >
        <div className="flex gap-5">
          <Select
            id="select-department"
            className="!w-[200px]"
            state={departmentOptions}
            placeholder="Select a department"
            setState={setDepartmentOptions}
            disabled={
              planOptions.options.length === 0 ||
              departmentOptions.options.length === 0
            }
          />
          <Select
            id="select-plan"
            className="!w-[200px]"
            state={planOptions}
            placeholder="No Locked Plans"
            setState={setPlanOptions}
            disabled={planOptions.options.length === 0}
          />
          <DateRange state={periodDates} setState={setPeriodDates} />
        </div>
      </Header>
      <div className="flex flex-col p-5 pt-10">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-6">
          <BudgetCard
            dateRange={periodDates}
            isReadyToRender={!isLoading}
            planToCompare={planOptions.selected?.value ?? undefined}
            linkToVariances={false}
            departmentUuids={
              departmentOptions.selected?.value
                ? [departmentOptions.selected.value]
                : undefined
            }
          />
        </div>
        <div className="mb-2 flex justify-end gap-4">
          <SegmentedControl
            name="role-department-view"
            value={selectedView}
            disabled={isLoading}
            setValue={(val) => setSelectedView(val as SelectedViewType)}
            segments={[
              {
                label: "Itemized",
                value: "itemized",
              },
              {
                label: "Department",
                value: "department",
              },
            ]}
          />
          <ExportData
            id={`download-${selectedView}-csv`}
            data={csvToExport}
            filename={`variances-${selectedView}-${
              planOptions.selected?.label ?? "no-plan"
            }-${periodDates.mode}-${date(
              periodDates.startDate.toString(),
            ).format("YYYY-MMM")} to ${date(
              periodDates.endDate.toString(),
            ).format("YYYY-MMM")}.csv`}
            disabled={isLoading}
          />
        </div>
        {selectedView === "itemized" && (
          <Table
            rowClasses="last:font-bold"
            columnAlignment={[
              "left",
              "left",
              "left",
              "left",
              "left",
              "left",
              "right",
            ]}
            headers={[
              "ID Number",
              "Name",
              "Job Title",
              "Department",
              "Modification",
              "Effective Date",
              "Impact",
            ]}
            headerClassName="px-2 py-4"
            loadingState={{ isLoading, skeletonRows: 10 }}
            data={pageData?.roleVariance ?? null}
            emptyState={
              <div className="flex flex-col mx-auto my-10 items-center text-center w-1/3">
                <img
                  src={emptyVarianceStateIllustration}
                  alt="Empty Tasks Illustration"
                  className="w-72 h-auto"
                />
                <Typography
                  tag="h3"
                  size="md"
                  weight="semibold"
                  className="mt-4"
                >
                  Budget Variances
                </Typography>
                <Typography tag="p" size="md" color="secondary">
                  Once the plan is locked, you can see how the working model
                  deviates from the budget, including reasons like unexpected
                  salary changes, expense model adjustments, and incorrect hire
                  dates.
                </Typography>
              </div>
            }
          />
        )}
        {selectedView === "department" && (
          <Table
            rowClasses="last:font-bold"
            tableRowDataClasses={["w-3/4", "", ""]}
            columnAlignment={["left", "left", "right"]}
            headers={["Department", "Modifications", "Total Impact"]}
            headerClassName="px-6 py-4"
            loadingState={{
              isLoading,
              skeletonRows: 10,
            }}
            data={pageData?.departmentVariance ?? null}
            emptyState={
              <div className="flex flex-col mx-auto my-10 items-center text-center w-1/3">
                <img
                  src={emptyVarianceStateIllustration}
                  alt="Empty Tasks Illustration"
                  className="w-72 h-auto"
                />
                <Typography
                  tag="h3"
                  size="md"
                  weight="semibold"
                  className="mt-4"
                >
                  Budget Variances
                </Typography>
                <Typography tag="p" size="md" color="secondary">
                  Once the plan is locked, you can see how the working model
                  deviates from the budget, including reasons like unexpected
                  salary changes, expense model adjustments, and incorrect hire
                  dates.
                </Typography>
              </div>
            }
          />
        )}
      </div>
    </div>
  );
};

export default VariancesContainer;
