import React, { useEffect } from "react";
import Input, { useInput } from "~/components/Input";
import Select, { useSelect } from "~/components/Select";
import request from "~/utils/request";
import { useSelector } from "react-redux";
import { State } from "~/store";
import { endOfMonth, format, getYear, startOfMonth } from "date-fns";
import Typography from "~/components/Typography";
import Button from "~/components/Button";
import toast from "react-hot-toast";
import usePeriodPicker from "~/components/PeriodPicker/usePeriodPicker";
import PeriodPicker from "~/components/PeriodPicker";
import date from "~/utils/dates/date";
import ExpenseModelSegments, {
  useExpenseModelSegments,
} from "./ExpenseModelSegments";

interface ManageExpenseModelProps {
  onSave: () => void;
  expenseModelToUpdate?: string;
}

type ITypeOptions = "FIXED_PER_EMPLOYEE" | "PERCENT_EMPLOYEE_SALARY";

interface ICondition {
  field: string;
  condition: "equals";
  value: string[];
}

interface IExpenseModelSegment {
  name: string;
  value: number;
  conditions: ICondition[];
  uuid: string;
  createdAt: string;
  updatedAt: string;
}

export interface ICurrent {
  versionUuid: string;
  expenseUuid: string;
  parentUuid: string | null;
  organizationUuid: string;
  scenarioUuid: string | null;
  name: string;
  effectiveAt: string;
  type: "FIXED_PER_EMPLOYEE" | "PERCENT_EMPLOYEE_SALARY" | "MANUAL_ADJUSTMENT";
  changeDescription: string;
  createdAt: string;
  createdBy: string | null;
  updatedAt: string;
  deletedAt: string | null;
  deletedBy: string | null;
  expenseModelSegments: IExpenseModelSegment[];
}

export interface IExpense {
  expenseUuid: string;
  current: ICurrent;
  lastModified: string;
  canBeDeleted: boolean;
}

export interface IFetchExpenseModelsResponse {
  data: {
    data: IExpense;
  };
  status: number;
}

const ManageExpenseModel = ({
  onSave,
  expenseModelToUpdate,
}: ManageExpenseModelProps): React.ReactNode => {
  const { uuid: organizationUuid } = useSelector(
    (state: State) => state.organization,
  );
  const editMode = expenseModelToUpdate !== undefined;
  const [name, setName] = useInput({});
  const [periodDates, setPeriodDates] = usePeriodPicker({
    startDate: startOfMonth(date().toDate()),
    endDate: endOfMonth(date().toDate()),
    mode: "month",
  });
  const [expenseModelSegments, setExpenseModelSegments] =
    useExpenseModelSegments();
  const [changeDescription, setChangeDescription] = useInput(
    expenseModelToUpdate
      ? {
          validation: /./,
        }
      : {
          value: "New expense model",
          valid: true,
          validation: /./,
        },
  );
  const [type, setType] = useSelect({
    options: [
      {
        label: (
          <span>
            Percentage of Employees Total Compensation{" "}
            <span className="text-neutral-200">(per month)</span>
          </span>
        ),
        value: "PERCENT_EMPLOYEE_SALARY",
      },
      {
        label: (
          <span>
            Dollar Amount per Employee{" "}
            <span className="text-neutral-200">(per month)</span>
          </span>
        ),
        value: "FIXED_PER_EMPLOYEE",
      },
    ],
    selected: {
      label: (
        <span>
          Percentage of Employees Total Compensation{" "}
          <span className="text-neutral-200">(per month)</span>
        </span>
      ),
      value: "PERCENT_EMPLOYEE_SALARY",
    },
  });

  useEffect(() => {
    const getExpenseModel = async (): Promise<void> => {
      const expenseModelResponse = (await request({
        url: `/organizations/${organizationUuid}/expense-models/${expenseModelToUpdate}`,
        method: "GET",
      })) as IFetchExpenseModelsResponse;
      if (expenseModelResponse.status === 200) {
        const currentVersion = expenseModelResponse.data.data.current;
        setName({ ...name, value: currentVersion.name, valid: true });
        setType({
          ...type,
          selected: {
            label:
              currentVersion.type === "FIXED_PER_EMPLOYEE"
                ? "Fixed Amount"
                : "Percentage of Salary",
            value: currentVersion.type,
          },
          valid: true,
        });
        setPeriodDates({
          ...periodDates,
          startDate: startOfMonth(new Date(currentVersion.effectiveAt)),
          endDate: endOfMonth(new Date(currentVersion.effectiveAt)),
        });
        setExpenseModelSegments((prevState) => ({
          ...prevState,
          segments: currentVersion.expenseModelSegments,
          valid: true,
        }));
      }
    };
    if (expenseModelToUpdate) {
      getExpenseModel();
    }
  }, [expenseModelToUpdate]);

  const attemptSubmit = async (): Promise<void> => {
    if (
      expenseModelSegments.valid &&
      name.valid &&
      type.valid &&
      changeDescription.valid
    ) {
      const createExpenseUrlSuffix = expenseModelToUpdate
        ? `/${expenseModelToUpdate}/versions`
        : "";
      const createExpenseModelResponse = await request({
        url: `/organizations/${organizationUuid}/expense-models/${createExpenseUrlSuffix}`,
        method: "POST",
        body: {
          effectiveAt: format(periodDates.startDate, "yyyy-MM-dd"),
          type: !Array.isArray(type.selected) && type.selected?.value,
          changeDescription: changeDescription.value,
          name: name.value,
          expenseModelSegments: expenseModelSegments.segments.map(
            (segment) => segment.uuid,
          ),
        },
      });

      if (createExpenseModelResponse.status === 201) {
        toast.success("Expense model created");
        onSave();
      } else {
        toast.error("Unable to create expense model");
      }
    } else {
      setName((prevState) => ({
        ...prevState,
        pristine: false,
        touched: true,
      }));
      setPeriodDates((prevState) => ({
        ...prevState,
      }));
      setType((prevState) => ({
        ...prevState,
        pristine: false,
        touched: true,
      }));
      setExpenseModelSegments((prevState) => ({
        ...prevState,
        pristine: false,
        touched: true,
      }));
      setChangeDescription((prevState) => ({
        ...prevState,
        pristine: false,
        touched: true,
      }));
    }
  };

  if (Array.isArray(type.selected)) throw new Error("Type is not an array");
  const positionType = type.selected?.value as ITypeOptions;

  return (
    <div className="flex flex-col w-full space-y-4">
      <Typography size="xl" weight="bold">
        {`${editMode ? "Update " : "New "}`}Expense Model
      </Typography>
      <Input id="name" state={name} setState={setName} label="Name" />
      <div className="relative">
        <PeriodPicker
          label="Effective Month"
          minYear={getYear(new Date()) - 5}
          maxYear={getYear(new Date()) + 5}
          state={periodDates}
          setState={setPeriodDates}
        />
      </div>
      <Select id="type" state={type} setState={setType} label="Type" />
      <ExpenseModelSegments
        positionType={positionType}
        state={expenseModelSegments}
        setState={setExpenseModelSegments}
      />
      <div>
        <Typography weight="semibold">Change Reason</Typography>
        <Input
          type="textarea"
          id="change-description"
          state={changeDescription}
          setState={setChangeDescription}
          label="Description"
        />
      </div>
      <div className="w-full flex justify-between mt-3">
        <Button className="!w-auto !px-0" fill="clear" onClick={onSave}>
          Cancel
        </Button>
        <Button
          id="save-expense-model-button"
          className="!w-auto"
          onClick={attemptSubmit}
        >
          Save
        </Button>
      </div>
    </div>
  );
};
export default ManageExpenseModel;
