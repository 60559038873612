import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface OrganizationState {
  name: string;
  uuid: string;
  configuration: {
    fiscalYearStart: number;
    positionsCreated: boolean;
    integrationCreated: boolean;
    planCreated?: boolean;
  };
}

const initialState = {};

export const organizationSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    update: (state, action: PayloadAction<OrganizationState>) => action.payload,
    reset: () => initialState,
  },
});

export const { update, reset } = organizationSlice.actions;
export default organizationSlice.reducer;
