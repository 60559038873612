import Header from "~/components/Header";
import SegmentedControl from "~/components/SegmentedControl";
import Table, { TableData } from "~/components/Table";
import { State } from "~/store";
import request from "~/utils/request";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import useQueryParams from "~/utils/hooks/useQueryParams";
import DatePicker, { useDatePicker } from "~/components/DatePicker";
import date from "~/utils/dates/date";
import months from "~/utils/dates/months";
import Typography from "~/components/Typography";
import ExportData from "~/components/ExportData";
import DrilldownDrawer, {
  useDrilldownDrawer,
} from "~/components/Drawer/DrilldownDrawer";
import { capitalizeWords } from "~/utils/capitalize";
import {
  addMonths,
  endOfMonth,
  format,
  parseISO,
  startOfMonth,
} from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { useFeatureFlag } from "@harnessio/ff-react-client-sdk";
import generateTableData from "./utils/generateTableData";
import {
  GroupsResponse,
  HeadcountOrSalary,
  ProjectionsResponse,
} from "./types";
import { v4 } from "uuid";
import { IOrganizationConfigurationResponse } from "~/components/SideMenu";
import MetricWarning from "~/components/MetricWarning";

const ForecastContainer = (): React.ReactNode => {
  /**
   * Store
   */
  const { uuid: organizationUuid } = useSelector(
    (state: State) => state.organization,
  );
  /**
   * Component state
   */
  const controllerRef = useRef<AbortController>();
  const [queryParams, setQueryParams] = useQueryParams();
  const [headcountOrSalary, setHeadcountOrSalary] = useState<HeadcountOrSalary>(
    (queryParams.get("format") as HeadcountOrSalary | undefined) ?? "salaries",
  );
  const [headers, setHeaders] = useState<string[]>([]);
  const [departments, setDepartments] = useState<Types.Group[]>([]);
  const [projections, setProjections] = useState<{
    currencyBasedProjections: {
      title: string;
      data: TableData[];
    }[];
    headcountBasedProjections: {
      title: string;
      data: TableData[];
    }[];
  }>();
  const [selectYearState, setSelectYearState] = useDatePicker({});
  const [isLoading, setIsLoading] = useState(true);
  const drilldownDrawerState = useDrilldownDrawer();
  const headcountTotalParity = Boolean(useFeatureFlag("headcountTotalParity"));
  const { total } = useSelector((state: State) => state.tasks);
  const [prevScrollPosition, setPrevScrollPosition] = useState<number>(0);

  useEffect(() => {
    const handleScroll = (): void => {
      setPrevScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (drilldownDrawerState.isOpen) {
      window.scrollTo(0, prevScrollPosition);
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [drilldownDrawerState.isOpen]);

  const getDepartments = async (): Promise<void> => {
    try {
      const groupsResponse = (await request({
        url: `/organizations/${organizationUuid}/groups`,
        method: "GET",
        params: {
          topLevel: queryParams.get("department") ? undefined : true,
          parentUuid: queryParams.get("department"),
        },
      })) as GroupsResponse;
      setDepartments(groupsResponse.data.data);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getDateRange = async (): Promise<void> => {
    try {
      const { data, status } = (await request({
        url: `/organizations/${organizationUuid}/settings`,
        method: "GET",
      })) as IOrganizationConfigurationResponse;
      if (status === 200) {
        if (data.data.fiscalYearStart) {
          const startDate = date().lastOccurrence(
            "month",
            months.indexOf(data.data.fiscalYearStart.toLowerCase()),
          );
          const endDate = date()
            .lastOccurrence(
              "month",
              months.indexOf(data.data.fiscalYearStart.toLowerCase()),
            )
            .add(1, "year")
            .subtract(1, "day");

          setSelectYearState((prevState) => ({
            ...prevState,
            value: {
              startDate: startDate.format("YYYY-MM-DD"),
              endDate: endDate.format("YYYY-MM-DD"),
            },
          }));
        }
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const openDrilldownDrawer = ({
    category,
    subCategory,
    departmentUuids,
    monthIndex,
    expenseModelUuid,
  }: {
    category: string;
    subCategory?: string;
    departmentUuids: string[];
    monthIndex: number;
    expenseModelUuid?: string;
  }): void => {
    if (!selectYearState.value.startDate) throw Error("Missing date range");

    let departmentName = "";

    if (departmentUuids.length === 1 && !!departmentUuids[0]) {
      departmentName =
        departments.find((department) => department.uuid === departmentUuids[0])
          ?.name ?? "Not found";
    } else if (subCategory) {
      departmentName = subCategory;
    } else {
      departmentName = "Total";
      departmentUuids = departments.map((department) => department.uuid);
    }

    const parsedDate = utcToZonedTime(
      parseISO(new Date(selectYearState.value.startDate).toISOString()),
      "UTC",
    );
    const relativeDate = addMonths(parsedDate, monthIndex);

    drilldownDrawerState.open({
      title: `${capitalizeWords(category)}`,
      subCategory: subCategory,
      subtitle: `${format(relativeDate, "MMMM yyyy")} | ${departmentName}`,
      startDate: format(startOfMonth(relativeDate), "yyyy-MM-dd"),
      endDate: format(endOfMonth(relativeDate), "yyyy-MM-dd"),
      departmentUuids: departmentUuids,
      category,
      expenseModelUuid,
    });
  };

  const fetchProjections = async (): Promise<void> => {
    if (!selectYearState.value.endDate || !selectYearState.value.startDate)
      throw Error("Missing date range");

    /**
     * Set headers based on data range provided
     */
    const numberOfMonthsInReport =
      date(selectYearState.value.endDate).diff(
        selectYearState.value.startDate,
        "month",
      ) + 1;
    const monthsToDisplay = Array.from(
      { length: numberOfMonthsInReport },
      (_, index) => {
        if (!selectYearState.value.startDate) throw Error("Missing start date");
        const month = date(selectYearState.value.startDate).add(index, "month");
        return month.format("MMM");
      },
    );
    setHeaders(["Department", ...monthsToDisplay]);

    /**
     * Make request to get projections
     */
    try {
      if (controllerRef.current) controllerRef.current.abort();
      controllerRef.current = new AbortController();
      const { signal } = controllerRef.current;

      const projectionsResponse = (await request({
        url: `/organizations/${organizationUuid}/projections`,
        method: "GET",
        params: {
          startDate: date(selectYearState.value.startDate).format("YYYY-MM-DD"),
          endDate: date(selectYearState.value.endDate).format("YYYY-MM-DD"),
          departments: departments.map((department) => department.uuid),
        },
        signal,
      })) as ProjectionsResponse;
      // Top level iterates over all the projections available
      const currencyBasedTableData = generateTableData({
        headcountTotalParity: headcountTotalParity as boolean,
        projections: projectionsResponse.data.data.currencyProjections,
        openDrilldownDrawer,
      });
      let numberBasedTableData = generateTableData({
        headcountTotalParity: headcountTotalParity as boolean,
        projections: [projectionsResponse.data.data.headcountProjections[0]],
        openDrilldownDrawer,
      });

      // Conditionally add the second table data based on the feature flag
      if (
        headcountTotalParity &&
        projectionsResponse.data.data.headcountProjections[1]
      ) {
        numberBasedTableData = [
          ...numberBasedTableData,
          ...generateTableData({
            headcountTotalParity: headcountTotalParity as boolean,
            projections: [
              projectionsResponse.data.data.headcountProjections[1],
            ],
            openDrilldownDrawer,
            includeTotalRow: false,
          }),
        ];
      }
      setProjections({
        currencyBasedProjections: currencyBasedTableData,
        headcountBasedProjections: numberBasedTableData,
      });
      setIsLoading(false);
    } catch (error) {
      if (error.name !== "CanceledError") {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    setQueryParams({
      format: headcountOrSalary,
    });
  }, [headcountOrSalary]);

  /**
   * Fetch departments on initial load
   */
  useEffect(() => {
    getDepartments();
  }, []);

  useEffect(() => {
    getDateRange();
  }, []);

  useEffect(() => {
    if (
      departments.length > 0 &&
      selectYearState.value.startDate &&
      selectYearState.value.endDate
    ) {
      fetchProjections();
    }
  }, [departments, selectYearState.value]);

  const renderTables = (
    headersForTable?: string[],
    projectionsForTables?: {
      currencyBasedProjections: {
        title: string;
        data: TableData[];
      }[];
      headcountBasedProjections: {
        title: string;
        data: TableData[];
      }[];
    },
  ): ReactElement | ReactElement[] => {
    if (!headersForTable) {
      return <div />;
    }

    let columnAlignment: (
      | "left"
      | "center"
      | "right"
      | "justify"
      | "char"
      | undefined
    )[] = ["left"];

    if (headersForTable.length) {
      columnAlignment = [
        "left",
        ...Array<"left" | "center" | "right" | "justify" | "char" | undefined>(
          headersForTable.length - 1,
        ).fill("right"),
      ];
    }

    return projectionsForTables && !isLoading ? (
      projectionsForTables[
        `${
          headcountOrSalary === "salaries"
            ? "currencyBasedProjections"
            : "headcountBasedProjections"
        }`
      ].map(({ title, data }, index) => (
        <div
          key={`${title}-${v4()}`}
          data-testid={`forecast-table-for-${title}`}
          className="flex flex-col"
        >
          <div
            data-testid={`forecast-header-${title}`}
            className="flex flex-row justify-between pr-8"
          >
            <Typography size="xl" weight="bold">
              {title}
            </Typography>
            <div className="flex flex-row gap-2">
              {index === 0 && total > 0 && <MetricWarning />}
              {index === 0 && headersForTable && projections && (
                <ExportData
                  id={`download-${headcountOrSalary}-projections-csv`}
                  data={projections[
                    `${
                      headcountOrSalary === "salaries"
                        ? "currencyBasedProjections"
                        : "headcountBasedProjections"
                    }`
                  ].reduce(
                    (output, projection) => {
                      output.push([projection.title]);
                      output.push([...headersForTable]);
                      output.push(
                        ...projection.data.map(({ values }) =>
                          values.map(({ value }) => value),
                        ),
                      );
                      return output;
                    },
                    [] as (string | number | boolean | React.ReactNode)[][],
                  )}
                  filename={`forecasts-${headcountOrSalary}-${date(
                    selectYearState.value.startDate ?? undefined,
                  ).format("YYYY-MMM")} to ${date(
                    selectYearState.value.endDate ?? undefined,
                  ).format("YYYY-MMM")}.csv`}
                  disabled={isLoading}
                />
              )}
            </div>
          </div>
          <Table
            className="mt-3 mb-10"
            rowClasses="last:font-bold"
            headers={headersForTable}
            data={data}
            columnAlignment={columnAlignment}
          />
        </div>
      ))
    ) : (
      <div className="flex flex-col">
        <div className="flex flex-row justify-between pr-8">
          <Typography id="forecast-header-totals" size="xl" weight="bold">
            Salaries
          </Typography>
          <div className="flex flex-row gap-2">
            {total > 0 && <MetricWarning />}
            <ExportData
              id="export-data-loading"
              data={[[]]}
              filename=""
              disabled={isLoading}
            />
          </div>
        </div>
        <Table
          className="mt-3 mb-10"
          rowClasses="last:font-bold"
          headers={headers}
          loadingState={{ isLoading, skeletonRows: 10 }}
          data={[{ id: "never", values: [] }]}
          columnAlignment={columnAlignment}
        />
      </div>
    );
  };

  return (
    <div>
      <Header title="Forecast">
        <div className="w-50 flex justify-center gap-2">
          <DatePicker
            label=""
            labelPlacement="left"
            id="select-year"
            state={selectYearState}
            setState={(val) => {
              setIsLoading(true);
              setSelectYearState(val);
            }}
            range
            className="min-w-[275px]"
          />
          <SegmentedControl
            name="headcount-salaries-view"
            value={headcountOrSalary}
            setValue={(val) => {
              setHeadcountOrSalary(
                val as React.SetStateAction<HeadcountOrSalary>,
              );
            }}
            segments={[
              {
                label: "Salaries",
                value: "salaries",
              },
              {
                label: "Headcount",
                value: "headcount",
              },
            ]}
          />
        </div>
      </Header>
      <div className="container !max-w-full flex flex-col !m-0 !pl-10 !pr-0 pt-10">
        {renderTables(headers, projections)}
      </div>
      <DrilldownDrawer state={drilldownDrawerState} />
    </div>
  );
};

export default ForecastContainer;
