import React from "react";
import { capitalCase } from "change-case";
import date from "~/utils/dates/date";
import { State as ComboboxState } from "~/components/Combobox/Combobox.types";
import { SelectState } from "~/components/Select/Select.types";
import { DatePickerState } from "~/components/DatePicker/useDatePicker";
import { Position } from "../../headcount.types";
import InputState = Types.InputState;

interface IPositionFormState {
  jobTitle: InputState;
  setJobTitle: React.Dispatch<React.SetStateAction<InputState>>;
  managerState: ComboboxState;
  setManagerState: React.Dispatch<React.SetStateAction<ComboboxState>>;
  setSelectDepartment: React.Dispatch<React.SetStateAction<SelectState>>;
  employmentType: SelectState;
  setEmploymentType: React.Dispatch<React.SetStateAction<SelectState>>;
  selectedPaymentType: SelectState;
  setSelectedPaymentType: React.Dispatch<React.SetStateAction<SelectState>>;
  paymentAmountState: InputState;
  setPaymentAmountState: React.Dispatch<React.SetStateAction<InputState>>;
  bonusAmountState: InputState;
  setBonusAmountState: React.Dispatch<React.SetStateAction<InputState>>;
  commissionAmountState: InputState;
  setCommissionAmountState: React.Dispatch<React.SetStateAction<InputState>>;
  attainmentState: InputState;
  setAttainmentState: React.Dispatch<React.SetStateAction<InputState>>;
  currencyTypeState: SelectState;
  setCurrencyTypeState: React.Dispatch<React.SetStateAction<SelectState>>;
  expectedHoursState: InputState;
  setExpectedHoursState: React.Dispatch<React.SetStateAction<InputState>>;
  startDateState: DatePickerState;
  setStartDateState: React.Dispatch<React.SetStateAction<DatePickerState>>;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
}

interface Props {
  positionFormState: IPositionFormState;
  positionData: Position;
}

const setPositionFormState = ({
  positionFormState,
  positionData,
}: Props): void => {
  const {
    setErrorMessage,
    jobTitle,
    setJobTitle,
    employmentType: employmentTypeState,
    setEmploymentType,
    selectedPaymentType,
    setSelectedPaymentType,
    paymentAmountState,
    setPaymentAmountState,
    currencyTypeState,
    setCurrencyTypeState,
    setSelectDepartment,
    managerState,
    setManagerState,
    expectedHoursState,
    setExpectedHoursState,
    bonusAmountState,
    setBonusAmountState,
    commissionAmountState,
    setCommissionAmountState,
    attainmentState,
    setAttainmentState,
    startDateState,
    setStartDateState,
  } = positionFormState;

  const {
    title,
    employmentType,
    compensationRate,
    bonus,
    commission,
    attainment,
    paymentUnit,
    expectedWeeklyHours,
    currency,
    effectiveAt,
  } = positionData.current;

  setJobTitle({
    ...jobTitle,
    value: title || "No Title",
    valid: true,
  });
  setSelectDepartment((prevState) => ({
    ...prevState,
    selected: positionData.currentDepartment?.uuid
      ? {
          label: positionData.currentDepartment.name,
          value: positionData.currentDepartment.uuid,
        }
      : {
          label: null,
          value: null,
        },
    valid: true,
  }));
  setManagerState({
    ...managerState,
    selected: {
      label: positionData.currentManager
        ? `${positionData.currentManager.firstName} ${positionData.currentManager.lastName}`
        : null,
      value: positionData.currentManager?.uuid ?? null,
    },
    valid: true,
  });
  setEmploymentType({
    ...employmentTypeState,
    selected: employmentType
      ? {
          label: capitalCase(employmentType),
          value: employmentType,
        }
      : undefined,
    valid: true,
  });
  setSelectedPaymentType({
    ...selectedPaymentType,
    selected: paymentUnit
      ? {
          label: capitalCase(paymentUnit),
          value: paymentUnit,
        }
      : undefined,
    valid: true,
  });
  setPaymentAmountState({
    ...paymentAmountState,
    value: compensationRate ? (compensationRate / 100).toString() : "",
    valid: true,
  });
  setCurrencyTypeState({
    ...currencyTypeState,
    selected: currency
      ? {
          label: currency,
          value: currency,
        }
      : undefined,
    valid: true,
  });
  setExpectedHoursState({
    ...expectedHoursState,
    value: expectedWeeklyHours?.toString() ?? "",
    valid: true,
  });
  setBonusAmountState({
    ...bonusAmountState,
    value: bonus ? (bonus / 100).toString() : "",
    valid: true,
  });
  setCommissionAmountState({
    ...commissionAmountState,
    value: commission ? (commission / 100).toString() : "",
    valid: true,
  });
  setAttainmentState({
    ...attainmentState,
    value: attainment ? (attainment * 100).toString() : "",
    valid: true,
  });
  setStartDateState({
    ...startDateState,
    value: {
      startDate: date(effectiveAt).format("MM/DD/YYYY"),
      endDate: date(effectiveAt).format("MM/DD/YYYY"),
    },
    minDate: date().toISOString(),
    valid: true,
  });
  setErrorMessage("");
};

export default setPositionFormState;
