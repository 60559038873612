import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { State } from "~/store";
import request from "~/utils/request";
import { useNavigate, useParams } from "react-router-dom";
import permissionsCheck from "~/utils/permissionsCheck";
import { IFetchExpenseModelsResponse } from "./ManageExpenseModel";
import ExpenseModelDetails from "./ExpenseModelDetails";
import ManualAdjustmentDetails from "./ManualAdjustments/ManualAdjustmentDetails";
import { IExpense } from "./expenseModel.types";

const ExpenseModel = (): React.ReactNode | null => {
  const navigate = useNavigate();
  const [expenseModel, setExpenseModel] = useState<IExpense>();
  const { uuid: organizationUuid } = useSelector(
    (state: State) => state.organization,
  );
  const {
    permissions: { role, departmentAccessList },
  } = useSelector((state: State) => state.user);
  const { expenseUuid } = useParams<{ expenseUuid: string }>();

  useEffect(() => {
    if (!permissionsCheck(role, departmentAccessList)) navigate("/dashboard");
  }, []);

  const fetchExpenseDetails = async (): Promise<void> => {
    const expenseDetailsResponse = (await request({
      url: `/organizations/${organizationUuid}/expense-models/${expenseUuid}`,
      method: "GET",
    })) as IFetchExpenseModelsResponse;

    if (expenseDetailsResponse.status !== 200) {
      throw new Error("Failed to fetch expense details");
    }

    setExpenseModel(expenseDetailsResponse.data.data);
  };

  useEffect(() => {
    fetchExpenseDetails();
  }, []);

  switch (expenseModel?.current.type) {
    case "PERCENT_EMPLOYEE_SALARY":
    case "FIXED_PER_EMPLOYEE":
      return (
        <ExpenseModelDetails
          expenseModel={expenseModel}
          organizationUuid={organizationUuid}
          fetchExpenseDetails={fetchExpenseDetails}
        />
      );
    case "MANUAL_ADJUSTMENT":
      return (
        <ManualAdjustmentDetails
          expenseModel={expenseModel}
          organizationUuid={organizationUuid}
          canBeDeleted={expenseModel.canBeDeleted}
          fetchExpenseDetails={fetchExpenseDetails}
        />
      );
    default:
      return null;
  }
};

export default ExpenseModel;
