import {
  ArrowLeftOnRectangleIcon,
  CogIcon,
  MapIcon,
  NewspaperIcon,
  PresentationChartLineIcon,
  UserGroupIcon,
  ChatBubbleLeftRightIcon,
  Square3Stack3DIcon,
  ListBulletIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
  ArrowPathIcon,
} from "@heroicons/react/24/outline";
import { State } from "~/store";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "~/assets/parallelLogoIcon.svg";
import request from "~/utils/request";
import { tasksSlice } from "~/store/tasksSlice";
import permissionsCheck from "~/utils/permissionsCheck";
import Pusher from "pusher-js";
import Typography from "../Typography";
import useHover from "~/utils/hooks/useHover";
import parallelLogo from "~/assets/parallelWordmarkLogo.svg";
import { userSlice, IUserPreferences } from "~/store/userSlice";
import Button from "../Button";
import { organizationSlice } from "~/store/organizationSlice";
import SwitchOrgs from "./SwitchOrgs";
import { ITaskSummaryResponse } from "~/pages/Dashboard/TasksCard";

interface Props {
  children: React.ReactNode;
  showRefresh: boolean;
}

interface IUserPreferencesResponse {
  data: {
    data: IUserPreferences;
  };
  status: number;
}

export interface IOrganizationConfigurationResponse {
  data: {
    data?: {
      organizationUuid: string;
      positionsCreated: boolean;
      integrationCreated: boolean;
      fiscalYearStart: string;
    };
  };
  status: number;
}

export interface PlansResponse {
  data: {
    data: {
      uuid: string;
      label: string;
    }[];
  };
}

const SideMenu = ({ children, showRefresh }: Props): React.ReactNode => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const {
    uuid: userUuid,
    preferences,
    permissions: { role, departmentAccessList },
  } = useSelector((state: State) => state.user);
  const { sideMenuExpanded } = preferences;
  const orgSlice = useSelector((state: State) => state.organization);
  const {
    uuid: organizationUuid,
    configuration: { planCreated, positionsCreated },
  } = orgSlice;
  const { total } = useSelector((state: State) => state.tasks);
  const [ref, hovering, setHovering] = useHover();
  const [onboardingNotComplete, setOnboardingNotComplete] = useState(false);

  const fetchPlanStatus = async (): Promise<void> => {
    const fetchPlansResponse = (await request({
      url: `/organizations/${organizationUuid}/plans`,
      method: "GET",
    })) as PlansResponse;

    const planIsCreated = fetchPlansResponse.data.data.length > 0;

    if (!planIsCreated) {
      setOnboardingNotComplete(true);
    }

    dispatch(
      organizationSlice.actions.update({
        ...orgSlice,
        configuration: {
          ...orgSlice.configuration,
          planCreated: planIsCreated,
        },
      }),
    );
  };
  useEffect(() => {
    const pusher = new Pusher(import.meta.env.VITE_PUSHER_APP_KEY, {
      cluster: "us2",
    });
    const channel = pusher.subscribe(organizationUuid);
    channel.bind("organization-created-plan", async () => {
      fetchPlanStatus();
    });

    fetchPlanStatus();

    return () => {
      pusher.unsubscribe(organizationUuid);
    };
  }, []);

  const updateSideMenuPreference = async (
    updatedValue: boolean,
  ): Promise<void> => {
    try {
      dispatch(
        userSlice.actions.setPreferences({
          ...preferences,
          sideMenuExpanded: updatedValue,
        }),
      );

      const userPreferenceResponse = (await request({
        url: `/users/${userUuid}/preferences`,
        method: "PATCH",
        body: {
          sideMenuExpanded: updatedValue,
        },
      })) as IUserPreferencesResponse;

      if (userPreferenceResponse.status >= 400) {
        throw Error("Failed to update user preferences");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getOrganizationConfiguration = async (): Promise<void> => {
    try {
      const organizationConfigurationResponse = (await request({
        url: `/organizations/${organizationUuid}/settings`,
        method: "GET",
      })) as IOrganizationConfigurationResponse;

      if (organizationConfigurationResponse.status >= 400)
        throw Error("Failed to fetch organization configuration");

      if (organizationConfigurationResponse.data.data) {
        const { positionsCreated: positionHasBeenCreated } =
          organizationConfigurationResponse.data.data;

        if (!positionHasBeenCreated) {
          setOnboardingNotComplete(true);
        }
        dispatch(
          organizationSlice.actions.update({
            ...orgSlice,
            configuration: {
              ...orgSlice.configuration,
              positionsCreated: positionHasBeenCreated,
            },
          }),
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const pusher = new Pusher(import.meta.env.VITE_PUSHER_APP_KEY, {
      cluster: "us2",
    });
    const channel = pusher.subscribe(organizationUuid);
    channel.bind("organization-configuration-update", async () => {
      getOrganizationConfiguration();
    });

    getOrganizationConfiguration();

    return (): void => {
      pusher.unsubscribe(organizationUuid);
    };
  }, []);

  const getTaskSummaryTotal = async (): Promise<void> => {
    try {
      const taskSummaryResponse = (await request({
        url: `/organizations/${organizationUuid}/tasks/summary`,
        method: "GET",
      })) as ITaskSummaryResponse;

      if (taskSummaryResponse.status >= 400)
        throw Error("Failed to fetch task summary");

      const {
        unassignedEmployee = 0,
        backfillPosition = 0,
        outdatedChange = 0,
        outdatedPosition = 0,
        outdatedTermination = 0,
        requestNewPosition = 0,
        requestPositionForecast = 0,
        potentialTransfer = 0,
      } = taskSummaryResponse.data.data;

      const totalSummary =
        unassignedEmployee +
        backfillPosition +
        outdatedChange +
        outdatedPosition +
        outdatedTermination +
        requestNewPosition +
        requestPositionForecast +
        potentialTransfer;
      dispatch(tasksSlice.actions.update({ total: totalSummary }));
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    const pusher = new Pusher(import.meta.env.VITE_PUSHER_APP_KEY, {
      cluster: "us2",
    });
    const channel = pusher.subscribe(organizationUuid);
    channel.bind("task-update", async () => {
      getTaskSummaryTotal();
    });

    getTaskSummaryTotal();

    return (): void => {
      pusher.unsubscribe(organizationUuid);
    };
  }, []);

  useEffect(() => {
    if (planCreated && positionsCreated) {
      setOnboardingNotComplete(false);
    } else {
      setOnboardingNotComplete(true);
    }
  }, [planCreated, positionsCreated]);

  const menuWidth = sideMenuExpanded ? "min-w-[280px]" : "w-[72px]";
  const navItemStyle =
    "flex items-center justify-start p-2 py-2 space-x-2 rounded mb-2 ml-2 mr-2";
  const iconStyle = "h-6 w-6";
  const childClasses = `flex-grow ${
    sideMenuExpanded ? "max-w-[calc(100%-280px)]" : "max-w-[calc(100%-72px)]"
  }`;

  const isActive = (path: string): string =>
    location.pathname === path
      ? "bg-white text-green-500"
      : "text-green-500 hover:bg-white";

  const handleNavigateToTasks = (
    e: React.MouseEvent | React.KeyboardEvent,
  ): void => {
    e.stopPropagation();
    navigate("/tasks");
  };

  const handleNavigateToDashboard = (e: React.MouseEvent): void => {
    e.stopPropagation();
    navigate("/dashboard");
  };

  return (
    <div className="flex w-full">
      <div
        className={`z-40 transition-all duration-[150ms] ${menuWidth} h-screen sticky top-0`}
      >
        <div
          ref={ref}
          className={`flex flex-col h-full sidebar-bg border-r border-green-25 transition-all ${
            hovering
              ? `${sideMenuExpanded ? "w-full" : "w-[280px] shadow-xl"}`
              : "w-full"
          }`}
        >
          <div className="w-full">
            <div className="relative flex flex-row w-full start-end justify-end text-neutral-800 font-semibold text-xl px-[22px] py-4 mt-1 overflow-hidden">
              <div className="absolute top-6 left-[22px]">
                <img
                  src={logo}
                  alt="alt-logo"
                  className={`min-w-[27px] ${
                    sideMenuExpanded || hovering ? "hidden" : ""
                  }`}
                />
                <img
                  src={parallelLogo}
                  alt="Parallel logo"
                  className={`min-w-[107px] ${
                    sideMenuExpanded || hovering ? "" : "hidden"
                  }`}
                />
              </div>
              <div className="flex items-center min-h-[34.875px]">
                <div
                  className={`w-fit h-fit p-[2px] ${hovering && "bg-white"} 
                  text-green-500 rounded-full cursor-pointer transition-opacity duration-300 ${
                    sideMenuExpanded || hovering ? "opacity-100" : "opacity-0"
                  }`}
                  onClick={() => {
                    updateSideMenuPreference(!sideMenuExpanded);
                    setHovering(false);
                  }}
                >
                  {sideMenuExpanded ? (
                    <ChevronLeftIcon
                      className={`w-5 h-5 transition-opacity duration-300 ${
                        hovering ? "opacity-100" : "opacity-0"
                      }`}
                    />
                  ) : hovering ? (
                    <ChevronRightIcon className="w-5 h-5" />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <nav className="px-2 py-4 mt-3 text-green-500 flex flex-col flex-grow justify-between">
            <ul>
              <div className="transition-all">
                <Link
                  to="/onboarding"
                  data-testid="sidemenu-onboarding"
                  className={`${
                    onboardingNotComplete &&
                    permissionsCheck(role, departmentAccessList)
                      ? ""
                      : "hidden"
                  } `}
                >
                  <li className={`${navItemStyle} ${isActive("/onboarding")}`}>
                    <MapIcon
                      className={`flex-shrink-0 ${iconStyle}`}
                      aria-hidden="true"
                    />
                    <Typography
                      color="green"
                      className={` ${
                        sideMenuExpanded || hovering ? "" : "hidden"
                      } whitespace-nowrap overflow-hidden `}
                    >
                      Getting Started
                    </Typography>
                  </li>
                </Link>
              </div>
              <button
                type="button"
                className="w-full"
                onClick={handleNavigateToDashboard}
                data-testid="sidemenu-dashboard"
              >
                <li
                  className={`${navItemStyle} relative justify-between overflow-hidden ${isActive(
                    "/dashboard",
                  )}`}
                >
                  <div className="flex flex-row space-x-2">
                    <div className="h-full flex flex-row">
                      <NewspaperIcon
                        className={`flex-shrink-0 ${iconStyle}`}
                        aria-hidden="true"
                      />
                      {total > 0 && !sideMenuExpanded && !hovering && (
                        <div className="absolute right-[4px] top-[6px] w-[6px] h-[6px] rounded-full bg-green-400" />
                      )}
                    </div>
                    <Typography
                      color="green"
                      className={` ${
                        sideMenuExpanded || hovering ? "" : "hidden"
                      } whitespace-nowrap overflow-hidden `}
                    >
                      Dashboard
                    </Typography>
                  </div>
                  {total > 0 && (sideMenuExpanded || hovering) && (
                    <div
                      role="button"
                      onClick={handleNavigateToTasks}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleNavigateToTasks(e);
                        }
                      }}
                      tabIndex={0}
                      className="px-2 bg-green-400 text-white hover:bg-green-500 rounded-full"
                    >
                      {total}
                    </div>
                  )}
                </li>
              </button>
              <Link to="/headcount" data-testid="sidemenu-headcount">
                <li className={`${navItemStyle} ${isActive("/headcount")}`}>
                  <UserGroupIcon
                    className={`flex-shrink-0 ${iconStyle}`}
                    aria-hidden="true"
                  />
                  <Typography
                    color="green"
                    className={` ${
                      sideMenuExpanded || hovering ? "" : "hidden"
                    } whitespace-nowrap overflow-hidden `}
                  >
                    Headcount
                  </Typography>
                </li>
              </Link>
              <Link to="/forecast" data-testid="sidemenu-forecast">
                <li className={`${navItemStyle} ${isActive("/forecast")}`}>
                  <PresentationChartLineIcon
                    className={`flex-shrink-0 ${iconStyle}`}
                    aria-hidden="true"
                  />
                  <Typography
                    color="green"
                    className={` ${
                      sideMenuExpanded || hovering ? "" : "hidden"
                    } whitespace-nowrap overflow-hidden `}
                  >
                    Forecast
                  </Typography>
                </li>
              </Link>
              <div
                className={`${
                  permissionsCheck(role, departmentAccessList) ? "" : "hidden"
                }`}
              >
                <Link
                  to="/plans-and-scenarios"
                  data-testid="sidemenu-plans-and-scenarios"
                >
                  <li
                    className={`${navItemStyle} ${isActive(
                      "/plans-and-scenarios",
                    )}`}
                  >
                    <Square3Stack3DIcon
                      className={`flex-shrink-0 ${iconStyle}`}
                      aria-hidden="true"
                    />
                    <Typography
                      color="green"
                      className={` ${
                        sideMenuExpanded || hovering ? "" : "hidden"
                      } whitespace-nowrap overflow-hidden `}
                    >
                      Plans & Scenarios
                    </Typography>
                  </li>
                </Link>
                <Link
                  to="/expense-models"
                  data-testid="sidemenu-expense-models"
                >
                  <li
                    className={`${navItemStyle} ${isActive("/expense-models")}`}
                  >
                    <ListBulletIcon
                      className={`flex-shrink-0 ${iconStyle}`}
                      aria-hidden="true"
                    />
                    <Typography
                      color="green"
                      className={` ${
                        sideMenuExpanded || hovering ? "" : "hidden"
                      } whitespace-nowrap overflow-hidden `}
                    >
                      Expense Models
                    </Typography>
                  </li>
                </Link>
              </div>
            </ul>
            <ul>
              <Link
                to="/settings"
                className={`${
                  permissionsCheck(role, departmentAccessList) ? "" : "hidden"
                }`}
                data-testid="sidemenu-settings"
              >
                <li className={`${navItemStyle} ${isActive("/settings")}`}>
                  <CogIcon
                    className={`flex-shrink-0 ${iconStyle}`}
                    aria-hidden="true"
                  />
                  <Typography
                    color="green"
                    className={` ${
                      sideMenuExpanded || hovering ? "" : "hidden"
                    } whitespace-nowrap overflow-hidden `}
                  >
                    Settings
                  </Typography>
                </li>
              </Link>
              <li
                id="chat-launcher"
                className={`cursor-pointer text-green-500 hover:bg-white ${navItemStyle}`}
                data-testid="sidemenu-chat-launcher"
              >
                <ChatBubbleLeftRightIcon
                  className={`flex-shrink-0 ${iconStyle}`}
                  aria-hidden="true"
                />
                <Typography
                  color="green"
                  className={` ${
                    sideMenuExpanded || hovering ? "" : "hidden"
                  } whitespace-nowrap overflow-hidden `}
                >
                  Support
                </Typography>
              </li>
              <Link to="/auth/logout" data-testid="sidemenu-logout">
                <li className={`${navItemStyle} ${isActive("/auth/logout")}`}>
                  <ArrowLeftOnRectangleIcon
                    className={`flex-shrink-0 ${iconStyle}`}
                    aria-hidden="true"
                  />
                  <Typography
                    color="green"
                    className={` ${
                      sideMenuExpanded || hovering ? "" : "hidden"
                    } whitespace-nowrap overflow-hidden `}
                  >
                    Log Out
                  </Typography>
                </li>
              </Link>
              <SwitchOrgs />
              {showRefresh && (
                <li className={`${navItemStyle}`}>
                  <ArrowPathIcon
                    className={`flex-shrink-0 ${iconStyle} ${
                      sideMenuExpanded || hovering ? "hidden" : ""
                    }`}
                    aria-hidden="true"
                  />
                  <Button
                    fill="outline"
                    className={`${
                      sideMenuExpanded || hovering ? "" : "hidden"
                    } whitespace-nowrap overflow-hidden`}
                    onClick={() => window.location.reload()}
                  >
                    Update Version
                  </Button>
                </li>
              )}
            </ul>
          </nav>
        </div>
      </div>
      <div className={childClasses}>{children}</div>
    </div>
  );
};

export default SideMenu;
