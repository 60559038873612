import React from "react";
import { Filter } from "~/components/SearchFilters/useSearchFilters";
import SelectDepartment from "~/components/SelectDepartment";
import { useSelect } from "~/components/Select";
import Button from "~/components/Button";
import { FilterType } from "~/components/SearchFilters/index";
import { RectangleGroupIcon } from "@heroicons/react/24/outline";

interface Props {
  activeFilters: Filter[];
  onSave: (filter: Filter[]) => void;
  onClose: () => void;
}

const DepartmentFilter = ({
  activeFilters,
  onSave,
  onClose,
}: Props): React.ReactNode => {
  const [selectDepartment, setSelectDepartment] = useSelect({
    options: [],
  });
  const attemptSave = (): void => {
    /*
     * const selectedDepartment = selectDepartment.options.find(
     *   (option) => option.value === selectDepartment.value
     * );
     */
    if (selectDepartment.selected?.value) {
      const activeFilter = activeFilters.find(
        (filter) => filter.id === FilterType.DEPARTMENT,
      );
      if (activeFilter) {
        // Update existing filter
      } else {
        const newFilter: Filter = {
          id: FilterType.DEPARTMENT,
          label: (
            <div className="flex flex-row gap-2">
              <RectangleGroupIcon className="w-5 h-5" />{" "}
              <p
                title={selectDepartment.selected.label as string}
                className="max-w-[125px] truncate"
              >
                {selectDepartment.selected.label}
              </p>
            </div>
          ),
          queryParam: {
            department: selectDepartment.selected.value,
          },
        };
        onSave([...activeFilters, newFilter]);
        onClose();
      }
    } else {
      setSelectDepartment({
        ...selectDepartment,
        pristine: false,
        touched: true,
      });
    }
  };
  return (
    <div className="text-neutral-900 text-left my-5">
      <SelectDepartment
        departmentOptions={selectDepartment}
        setDepartmentOptions={setSelectDepartment}
        label="Select a department"
      />
      <div className="flex justify-end gap-3 my-3">
        <Button
          fill="outline"
          className="text-red border-red !w-auto"
          id="filter-cancel"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button id="filter-save" className="!w-auto" onClick={attemptSave}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default DepartmentFilter;
