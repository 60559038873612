import React, { useEffect } from "react";
import Select from "~/components/Select";
import request from "~/utils/request";
import { useSelector } from "react-redux";
import { State } from "~/store";
import { SelectState, SelectType } from "~/components/Select/Select.types";
import arrowTurnRight from "~/assets/arrowTurnRight.svg";
import Typography from "../Typography";

interface Props {
  departmentOptions: SelectState;
  setDepartmentOptions: React.Dispatch<React.SetStateAction<SelectState>>;
  className?: string;
  listBoxClassName?: string;
  label?: string;
  placeholder?: string;
  allowSelectAll?: boolean;
}
interface Department {
  uuid: string;
  name: string;
  parentUuid: string | null;
}
interface DepartmentResponse {
  data: {
    data: Department[];
  } | null;
  status: number;
}

interface DepartmentHierarchy {
  uuid: string;
  name: string;
  parentUuid: string | null;
  childDepartments: DepartmentHierarchy[];
}
/**
 * Undefined is the value for the first 2 because no parent means no padding necessary, if you have 1 level of parenting
 * the 24px of padding that we need to add is taken up by the arrow right icon
 */
const labelPaddingArray = [
  undefined,
  undefined,
  "pl-[24px]",
  "pl-[48px]",
  "pl-[72px]",
  "pl-[96px]",
  "pl-[120px]",
  "pl-[144px]",
  "pl-[168px]",
];

const SelectDepartment = ({
  departmentOptions,
  setDepartmentOptions,
  className = "",
  listBoxClassName = "",
  label,
  placeholder,
  allowSelectAll = false,
}: Props): React.ReactNode => {
  const { uuid: organizationUuid } = useSelector(
    (state: State) => state.organization,
  );
  const [isDisabled, setIsDisabled] = React.useState(false);

  useEffect(() => {
    const getDepartments = async (): Promise<void> => {
      const fetchDepartmentsResponse = (await request({
        url: `/organizations/${organizationUuid}/groups`,
        method: "GET",
      })) as DepartmentResponse;
      if (fetchDepartmentsResponse.status >= 400) return;
      if (fetchDepartmentsResponse.data) {
        const { data } = fetchDepartmentsResponse.data;
        const options: SelectType[] = [];
        if (allowSelectAll && data.length > 1)
          options.push({ label: "All Departments", value: null });
        setDepartmentOptions((prevState) => ({
          ...prevState,
          selected: allowSelectAll
            ? { label: "All Departments", value: null }
            : prevState.selected,
          options: [
            ...options,
            ...data.map((department) => ({
              label: department.name,
              value: department.uuid,
            })),
          ],
        }));
        const departmentDict = data.reduce(
          (output: Record<string, Department>, department) => {
            const modifiedOutput = output;
            modifiedOutput[department.uuid] = department;
            return modifiedOutput;
          },
          {},
        );

        const buildHierarchy = (
          department: Department,
        ): DepartmentHierarchy => {
          const childDepartments = Object.values(departmentDict).reduce(
            (acc: Department[], group) => {
              if (group.parentUuid === department.uuid) {
                return [...acc, group];
              }
              return acc;
            },
            [],
          );

          return {
            uuid: department.uuid,
            name: department.name,
            parentUuid: department.parentUuid,
            childDepartments: childDepartments.map(buildHierarchy),
          };
        };

        const rootDepartments = Object.entries(departmentDict).reduce(
          (acc: DepartmentHierarchy[], [, department]) => {
            if (department.parentUuid === null) {
              return [...acc, buildHierarchy(department)];
            }
            return acc;
          },
          [],
        );

        const computeDepth = (nodeUuid: string): number => {
          let depth = 0;
          let currentNodeUuid = nodeUuid;
          while (departmentDict[currentNodeUuid].parentUuid !== null) {
            depth += 1;
            currentNodeUuid = departmentDict[currentNodeUuid]
              .parentUuid as string;
          }
          return depth;
        };

        const buildNodeValue = (nodeUuid: string): React.ReactNode | null => {
          const node = departmentDict[nodeUuid];
          const depth = computeDepth(nodeUuid);
          if (depth < 1) {
            return null;
          }

          return (
            <div
              title={node.name}
              className={`flex flex-row gap-2 ${labelPaddingArray[depth]}`}
            >
              <img
                src={arrowTurnRight}
                alt="Arrow Turn Right Icon"
                className="w-4 h-auto"
              />
              <Typography size="xs">{node.name}</Typography>
            </div>
          );
        };

        const convertChildrenToOptions = (
          nodes: DepartmentHierarchy[],
        ): {
          label: string;
          value: string | null;
          node?: React.ReactNode | null;
        }[] => {
          const output: {
            label: string;
            value: string | null;
            node?: React.ReactNode | null;
          }[] = [];
          if (allowSelectAll && nodes.length > 1) {
            output.push({ label: "All Departments", value: null });
          }
          nodes.forEach((node) => {
            output.push({
              label: node.name,
              value: node.uuid,
              node: buildNodeValue(node.uuid),
            });
            if (node.childDepartments.length > 0) {
              output.push(...convertChildrenToOptions(node.childDepartments));
            }
          });

          return output;
        };

        const departmentsToSet = convertChildrenToOptions(rootDepartments);

        if (departmentsToSet.length === 1) {
          setDepartmentOptions((prevState) => ({
            ...prevState,
            selected: {
              label: departmentsToSet[0].label,
              value: departmentsToSet[0].value,
              node: departmentsToSet[0].node,
            },
          }));
          setIsDisabled(true);
        } else {
          setIsDisabled(false);
        }

        setDepartmentOptions((prevState) => ({
          ...prevState,
          options: departmentsToSet,
        }));
      }
    };
    getDepartments();
  }, []);

  return (
    <Select
      id="department-select"
      label={label}
      state={departmentOptions}
      setState={setDepartmentOptions}
      className={className}
      placeholder={placeholder}
      disabled={isDisabled}
      listBoxClassName={listBoxClassName}
    />
  );
};

export default SelectDepartment;
