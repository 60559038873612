import React from "react";
import ProtectedRoute from "~/components/ProtectedRoute";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
} from "react-router-dom";

import ForgotPassword from "./pages/Auth/ForgotPassword";
import Login from "./pages/Auth/Login";
import LinkSent from "./pages/Auth/LinkSent";
import Logout from "./pages/Auth/Logout";
import Oauth from "./pages/Auth/OAuth";
import Dashboard from "./pages/Dashboard";
import Onboarding from "./pages/Onboarding";
import Headcount from "./pages/Headcount";
import PositionDetails from "./pages/Headcount/PositionDetails";
import Forecast from "./pages/Forecast";
import Settings from "./pages/Settings";
import ResetPassword from "./pages/Auth/ResetPassword";
import Tasks from "./pages/Tasks";
import SignUp from "./pages/Auth/SignUp";
import ImportPositions from "./pages/Headcount/ImportPositions";
import Variances from "./pages/Variances";
import Scenarios from "./pages/PlansAndScenarios";
import AllExpenseModels from "./pages/AllExpenseModels";
import ExpenseModel from "./pages/AllExpenseModels/ExpenseModel";
import Otp from "./pages/Auth/Otp";
import ErrorBoundary from "./pages/ErrorBoundary";

export default createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route
        path="/"
        element={<Navigate replace to="/auth/login" />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        path="auth/login"
        element={<Login />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        path="auth/link-sent"
        element={<LinkSent />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        path="auth/signup"
        element={<SignUp />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        path="auth/forgot-password"
        element={<ForgotPassword />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        path="auth/logout"
        element={<Logout />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        path="auth/reset-password"
        element={<ResetPassword />}
        errorElement={<ErrorBoundary />}
      />
      <Route
        path="auth/otp"
        element={<Otp />}
        errorElement={<ErrorBoundary />}
      />
      <Route element={<ProtectedRoute />}>
        <Route
          path="oauth"
          element={<Oauth />}
          errorElement={<ErrorBoundary />}
        />
        <Route
          path="onboarding"
          element={<Onboarding />}
          errorElement={<ErrorBoundary />}
        />
        <Route
          path="dashboard"
          element={<Dashboard />}
          errorElement={<ErrorBoundary />}
        />
        <Route
          path="headcount"
          element={<Headcount />}
          errorElement={<ErrorBoundary />}
        />
        <Route
          path="positions/:positionUuid"
          element={<PositionDetails />}
          errorElement={<ErrorBoundary />}
        />
        <Route
          path="positions/imports"
          element={<ImportPositions />}
          errorElement={<ErrorBoundary />}
        />
        <Route
          path="forecast"
          element={<Forecast />}
          errorElement={<ErrorBoundary />}
        />
        <Route
          path="settings"
          element={<Settings />}
          errorElement={<ErrorBoundary />}
        />
        <Route
          path="tasks"
          element={<Tasks />}
          errorElement={<ErrorBoundary />}
        />
        <Route
          path="variances"
          element={<Variances />}
          errorElement={<ErrorBoundary />}
        />
        <Route
          path="plans-and-scenarios"
          element={<Scenarios />}
          errorElement={<ErrorBoundary />}
        />
        <Route
          path="expense-models"
          element={<AllExpenseModels />}
          errorElement={<ErrorBoundary />}
        />
        <Route
          path="expense-models/:expenseUuid"
          element={<ExpenseModel />}
          errorElement={<ErrorBoundary />}
        />
      </Route>
      {/*Handles 404 error, must be last route*/}
      <Route path="*" element={<ErrorBoundary is404={true} />} />
    </Route>,
  ),
  {
    future: {
      v7_normalizeFormMethod: true,
    },
  },
);
