import { State } from "~/store";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Header from "~/components/Header";
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import utc from "dayjs/plugin/utc";
import Select, { useSelect } from "~/components/Select";
import request from "~/utils/request";
import useQueryParams from "~/utils/hooks/useQueryParams";
import date from "~/utils/dates/date";
import "dayjs/locale/en";
import usePeriodPicker from "~/components/PeriodPicker/usePeriodPicker";
import Typography from "~/components/Typography";
import SelectDepartment from "~/components/SelectDepartment";
import DateRange from "~/components/DateRange";
import { endOfMonth, format, startOfMonth, getQuarter } from "date-fns";
import HeadcountCard, { IHeadcount } from "./HeadcountCard";
import BudgetCard from "./BudgetCard";
import FutureHeadcountCard from "./FutureHeadcountCard";
import FirstTimeLogin from "../Onboarding/FirstTimeLogin";
import TasksCard from "./TasksCard";
import AnalyticsCard from "./AnalyticsCard";
import MetricWarning from "~/components/MetricWarning";

dayjs.extend(quarterOfYear);
dayjs.extend(utc);

export interface PlansResponse {
  data: {
    data: {
      uuid: string;
      label: string;
    }[];
  };
}

interface IPageDataState {
  isLoading: boolean;
  data?: {
    plannedHires: number;
    headcount: IHeadcount;
  };
  error?: string;
}

interface IAggregatedDataForDashboardResponse {
  data: {
    data: {
      headcount: IHeadcount;
      futureHeadcount: number;
    };
  } | null;
  status: number;
}

const Dashboard = (): React.ReactNode => {
  const controllerRef = useRef<AbortController>();
  const [queryParams, setQueryParams] = useQueryParams();
  const [firstTimeLoginModal, setFirstTimeLoginModal] = useState(
    queryParams.get("firstLogin") === "true" || null,
  );
  const [periodDates, setPeriodDates] = usePeriodPicker({
    startDate: startOfMonth(date().toDate()),
    endDate: endOfMonth(date().toDate()),
    mode: "month",
  });
  const [plansFetched, setPlansFetched] = useState<boolean>(false);
  const [planOptions, setPlanOptions] = useSelect({
    options: [],
  });
  const [pageData, setPageData] = useState<IPageDataState>({
    isLoading: true,
  });
  const { uuid: organizationUuid } = useSelector(
    (state: State) => state.organization,
  );
  const { name } = useSelector((state: State) => state.user);
  const [departmentOptions, setDepartmentOptions] = useSelect({
    options: [],
  });
  const { total } = useSelector((state: State) => state.tasks);

  useEffect(() => {
    setQueryParams({
      mode: periodDates.mode,
      startDate: format(periodDates.startDate, "yyyy-MM-dd"),
      endDate: format(periodDates.endDate, "yyyy-MM-dd"),
    });
  }, [periodDates]);

  useEffect(() => {
    const fetchPlans = async (): Promise<void> => {
      const fetchPlansResponse = (await request({
        url: `/organizations/${organizationUuid}/plans`,
        method: "GET",
      })) as PlansResponse;

      if (fetchPlansResponse.data.data.length > 0) {
        const firstOption =
          fetchPlansResponse.data.data[fetchPlansResponse.data.data.length - 1];
        setPlanOptions((prevState) => ({
          ...prevState,
          selected: {
            label: firstOption.label,
            value: firstOption.uuid,
          },
          options: fetchPlansResponse.data.data.map((plan) => ({
            label: plan.label,
            value: plan.uuid,
          })),
        }));
      } else {
        setPlansFetched(true);
      }
    };

    fetchPlans();
  }, [organizationUuid, setPlanOptions]);

  useEffect(() => {
    if (planOptions.options.length) {
      setPlansFetched(true);
    }
  }, [planOptions.options.length]);

  useEffect(() => {
    const getPageData = async (): Promise<void> => {
      try {
        if (controllerRef.current) controllerRef.current.abort();
        controllerRef.current = new AbortController();
        const { signal } = controllerRef.current;
        setPageData((prevState) => ({
          ...prevState,
          isLoading: true,
        }));

        const aggregatedDataResponse = (await request({
          url: `/organizations/${organizationUuid}/aggregated-data/dashboard`,
          method: "GET",
          params: {
            startDate: format(periodDates.startDate, "yyyy-MM-dd"),
            endDate: format(periodDates.endDate, "yyyy-MM-dd"),
            departments: departmentOptions.selected?.value
              ? [departmentOptions.selected.value]
              : null,
            planUuid: planOptions.selected?.value,
          },
          signal,
        })) as IAggregatedDataForDashboardResponse;
        if (aggregatedDataResponse.status >= 400)
          throw Error("Error fetching dashboard data");

        if (aggregatedDataResponse.data?.data) {
          setPageData({
            isLoading: false,
            data: {
              plannedHires: aggregatedDataResponse.data.data.futureHeadcount,
              headcount: aggregatedDataResponse.data.data.headcount,
            },
          });
        }
      } catch (e: unknown) {
        if (e.name !== "CanceledError" && e instanceof Error) {
          setPageData({
            isLoading: false,
            error: e.message,
          });
        }
      }
    };
    if (plansFetched) {
      getPageData();
    }
  }, [
    organizationUuid,
    plansFetched,
    planOptions.selected,
    periodDates,
    departmentOptions.selected,
  ]);

  let snapshotPeriodLabel = "";

  switch (periodDates.mode) {
    case "month":
      snapshotPeriodLabel = format(periodDates.startDate, "MMMM yyyy");
      break;
    case "quarter":
      snapshotPeriodLabel = `Q${getQuarter(periodDates.startDate)} ${format(
        periodDates.startDate,
        "yyyy",
      )}`;
      break;
    case "year":
      snapshotPeriodLabel = format(periodDates.startDate, "yyyy");
      break;
    default:
      // Handle default case or error
      snapshotPeriodLabel = "Invalid period mode";
  }

  const firstName = name.split(" ")[0];

  return (
    <div className="w-full">
      <FirstTimeLogin
        isOpen={firstTimeLoginModal ?? false}
        setModal={() => setFirstTimeLoginModal(null)}
      />
      <Header title="Dashboard">
        <div className="flex gap-5">
          <SelectDepartment
            departmentOptions={departmentOptions}
            setDepartmentOptions={setDepartmentOptions}
            allowSelectAll
            className="!w-[250px]"
            listBoxClassName="!max-w-[400px] !w-full"
          />
          <Select
            id="select-plan"
            className="!w-[200px]"
            state={planOptions}
            placeholder="No Locked Plans"
            setState={setPlanOptions}
            disabled={planOptions.options.length === 0}
          />
          <DateRange state={periodDates} setState={setPeriodDates} />
        </div>
      </Header>
      <div className="container py-11 px-5 2xl:px-0">
        <Typography id="headline-intro" tag="h2" size="xl" weight="bold">
          Welcome{firstName ? ` ${firstName}` : ""}
        </Typography>
        <Typography tag="p" size="md" color="secondary" className="mt-2">
          {`Here is a snapshot of your headcount for `}
          <span className="font-bold" data-testid="headline-current-date">
            {snapshotPeriodLabel}
          </span>
        </Typography>
        {total > 0 && <MetricWarning />}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-4">
          <HeadcountCard
            isLoading={pageData.isLoading}
            data={pageData.data ? pageData.data.headcount : undefined}
            periodDates={periodDates}
          />
          <BudgetCard
            dateRange={periodDates}
            departmentUuids={
              departmentOptions.selected?.value
                ? [departmentOptions.selected.value]
                : undefined
            }
            planToCompare={planOptions.selected?.value ?? undefined}
            isReadyToRender={plansFetched}
            linkToVariances
          />
          <FutureHeadcountCard
            isLoading={pageData.isLoading}
            plannedHires={pageData.data?.plannedHires}
          />
          <TasksCard />
        </div>
        <div className="mt-10">
          <AnalyticsCard
            isLoading={pageData.isLoading}
            planToCompare={planOptions.selected?.value ?? undefined}
            periodDates={periodDates}
            departmentOptions={departmentOptions}
          />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
