import React from "react";

type Size = "2xs" | "xs" | "sm" | "md" | "lg" | "xl" | "2xl";
interface Props {
  id?: string;
  className?: string;
  children: string | React.ReactNode;
  tag?: "span" | "p" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  size?: Size;
  weight?:
    | "thin"
    | "extralight"
    | "light"
    | "normal"
    | "medium"
    | "semibold"
    | "bold";
  color?:
    | "invert"
    | "primary"
    | "secondary"
    | "disabled"
    | "empty"
    | "warning"
    | "special"
    | "lightGray"
    | "green"
    | "subHead"
    | "graphBlue";
}

const Typography = React.forwardRef(
  (
    {
      id = "",
      className = "",
      children,
      tag = "span",
      size = "sm",
      weight = "normal",
      color = "primary",
    }: Props,
    forwardedRef: React.Ref<HTMLElement | undefined>,
  ) => {
    const lineHeight: { [K in Size]: number } = {
      "2xs": 4,
      xs: 5,
      sm: 6,
      md: 7,
      lg: 7,
      xl: 8,
      "2xl": 9,
    };

    const fontColor: { [K in typeof color]: string } = {
      invert: "neutral-0",
      primary: "neutral-800",
      secondary: "neutral-400",
      disabled: "neutral-100",
      empty: "neutral-200",
      warning: "red-500",
      special: "orange",
      lightGray: "neutral-300",
      green: "green-500",
      subHead: "neutral-700",
      graphBlue: "blue-500",
    };

    const Tag = tag;

    return (
      <Tag
        ref={forwardedRef}
        title={
          className.includes("truncate") && typeof children === "string"
            ? children
            : undefined
        }
        className={`tracking-normal text-${fontColor[color]} text-${size} font-${weight} leading-${lineHeight[size]} ${className}`}
        data-testid={id}
      >
        {children}
      </Tag>
    );
  },
);
export default Typography;
