import React, { useEffect, useState } from "react";
import Typography from "~/components/Typography";
import Button from "~/components/Button";
import Modal from "~/components/Modal";
import Input, { useInput } from "~/components/Input";
import Divider from "~/components/Divider";
import Popover from "~/components/Popover";
import { TrashIcon } from "@heroicons/react/24/outline";
import COUNTRY_OPTIONS from "~/utils/countryOptions";
import STATE_OPTIONS from "~/utils/stateOptions";
import request from "~/utils/request";
import { State } from "~/store";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import formatCurrency from "~/utils/formatCurrency";
import formatPercent from "~/utils/formatPercent";
import Checkbox from "~/components/Checkbox";
import SelectMultipleDepartments from "~/components/SelectMultipleDepartments";
import SelectMultiple, { useSelectMultiple } from "~/components/SelectMultiple";

interface IUsedLocation {
  state: string;
  country: string;
}

interface IUsedLocationsResponse {
  request: unknown;
  data: {
    data?: IUsedLocation[];
  };
  status: number;
}

interface ICondition {
  field: string;
  condition: "equals";
  value: string[];
}

interface ISegment {
  name: string;
  value: number;
  conditions: ICondition[];
  uuid?: string;
}

type ITypeOptions = "FIXED_PER_EMPLOYEE" | "PERCENT_EMPLOYEE_SALARY";

type IPositionTypeMapping = {
  [key in ITypeOptions]: {
    label: string;
    sublabel: string;
  };
};

interface IState {
  segments: ISegment[];
  valid: boolean;
  pristine: boolean;
  touched: boolean;
}

interface IProps {
  state: IState;
  setState: React.Dispatch<React.SetStateAction<IState>>;
  positionType: ITypeOptions;
}

const positionTypeMapping: IPositionTypeMapping = {
  FIXED_PER_EMPLOYEE: {
    label: "Dollar Amount Per Employee",
    sublabel: "Amount is pro-rated to the time period applied to the employee",
  },
  PERCENT_EMPLOYEE_SALARY: {
    label: "Percentage of salary",
    sublabel: "",
  },
};

export const useExpenseModelSegments = (): [
  IState,
  React.Dispatch<React.SetStateAction<IState>>,
] =>
  React.useState<IState>({
    segments: [],
    pristine: true,
    touched: false,
    valid: false,
  });

const ExpenseModelSegments = ({
  state,
  setState,
  positionType,
}: IProps): React.ReactNode => {
  const { uuid: organizationUuid } = useSelector(
    (store: State) => store.organization,
  );
  const [segmentEditMode, setSegmentEditMode] = useState(false);
  const [segmentUuidToUpdate, setSegmentUuidToUpdate] = useState<string>();
  const [nameInput, setNameInput, resetNameInput] = useInput({});
  const [departmentsOptions, setDepartmentsOptions, resetDepartmentOptions] =
    useSelectMultiple({
      multiple: true,
    });
  const [departmentOptionsActive, setDepartmentOptionsActive] = useState(false);
  const [amount, setAmount, resetAmount] = useInput({});
  const [fullTimeChecked, setFullTimeChecked] = useState<boolean>(true);
  const [partTimeChecked, setPartTimeChecked] = useState<boolean>(true);
  const [countryOptionsActive, setCountryOptionsActive] = useState(false);
  const [countryOptions, setCountryOptions, resetCountryOptions] =
    useSelectMultiple({
      options: COUNTRY_OPTIONS,
    });
  const [stateOptionsActive, setStateOptionsActive] = useState(false);
  const [stateOptions, setStateOptions, resetStateOptions] = useSelectMultiple({
    options: STATE_OPTIONS,
  });
  const [usedLocations, setUsedLocations] = useState<IUsedLocation[]>([]);

  const getUsedLocations = async (): Promise<void> => {
    const response = (await request({
      url: `/organizations/${organizationUuid}/locations`,
      method: "GET",
    })) as IUsedLocationsResponse;

    if (response.data.data) {
      setUsedLocations(response.data.data);
    }
  };

  useEffect(() => {
    getUsedLocations();
  }, []);

  useEffect(() => {
    if (usedLocations.length) {
      setStateOptions((prevState) => {
        const updatedStateArray = prevState.options.filter((option) =>
          usedLocations.some((location) => location.state === option.value),
        );
        return { ...prevState, options: updatedStateArray };
      });

      setCountryOptions((prevState) => {
        const updatedCountryArray = prevState.options.filter((option) =>
          usedLocations.some((location) => location.country === option.value),
        );
        return { ...prevState, options: updatedCountryArray };
      });
    }
  }, [usedLocations]);

  const enterEditSegmentMode = (segmentUuid: string): void => {
    const selectedSegment = state.segments.find(
      (segment) => segment.uuid === segmentUuid,
    );
    if (selectedSegment) {
      /**
       * Set name for editing
       */
      setNameInput((prevState) => ({
        ...prevState,
        value: selectedSegment.name,
        valid: true,
      }));

      /**
       * Set amount for editing
       */
      setAmount((prevState) => ({
        ...prevState,
        value: (selectedSegment.value / 100).toString(),
        valid: true,
      }));

      /**
       * Set department for editing
       */
      const departmentValues = selectedSegment.conditions.find(
        (condition) => condition.field === "departmentUuid",
      );
      if (departmentValues) {
        const selected = departmentsOptions.options.filter(
          (option) =>
            option.value && departmentValues.value.includes(option.value),
        );
        setDepartmentsOptions((prevState) => ({
          ...prevState,
          selected,
          valid: true,
        }));
        setDepartmentOptionsActive(true);
      }

      /**
       * Set country for editing
       */
      const countryValues = selectedSegment.conditions.find(
        (condition) => condition.field === "country",
      );
      if (countryValues) {
        const selected = countryOptions.options.filter(
          (option) =>
            option.value && countryValues.value.includes(option.value),
        );
        setCountryOptions((prevState) => ({
          ...prevState,
          selected,
          valid: true,
        }));
        setCountryOptionsActive(true);
      }

      /**
       * Set state for editing
       */
      const stateValues = selectedSegment.conditions.find(
        (condition) => condition.field === "state",
      );
      if (stateValues) {
        const selected = stateOptions.options.filter(
          (option) => option.value && stateValues.value.includes(option.value),
        );
        setStateOptions((prevState) => ({
          ...prevState,
          selected,
          valid: true,
        }));
        setStateOptionsActive(true);
      }
    }
    setSegmentUuidToUpdate(segmentUuid);
    setSegmentEditMode(true);
  };

  const closeSegmentEditMode = (): void => {
    setSegmentEditMode(false);
    resetAmount();
    setFullTimeChecked(true);
    setPartTimeChecked(true);
    resetNameInput();
    resetStateOptions();
    resetCountryOptions();
    setDepartmentsOptions((prevState) => ({
      ...prevState,
      valid: false,
      touched: false,
      pristine: true,
      selected: [],
    }));
    setDepartmentOptionsActive(false);
    setCountryOptionsActive(false);
    setStateOptionsActive(false);
  };

  const attemptDeleteSegment = (segmentUuid: string): void => {
    setState((prevState) => {
      const newSegments = prevState.segments.filter(
        (segment) => segment.uuid !== segmentUuid,
      );
      return {
        ...prevState,
        segments: newSegments,
        valid: newSegments.length > 0,
      };
    });
  };

  const attemptCreateSegment = async (): Promise<void> => {
    if (
      nameInput.valid &&
      amount.valid &&
      (fullTimeChecked || partTimeChecked)
    ) {
      const conditions: ICondition[] = [];

      if (fullTimeChecked && partTimeChecked) {
        conditions.push({
          field: "employmentType",
          condition: "equals",
          value: ["FULL_TIME", "PART_TIME"],
        });
      } else if (partTimeChecked) {
        conditions.push({
          field: "employmentType",
          condition: "equals",
          value: ["PART_TIME"],
        });
      } else if (fullTimeChecked) {
        conditions.push({
          field: "employmentType",
          condition: "equals",
          value: ["FULL_TIME"],
        });
      }

      if (
        departmentOptionsActive &&
        Array.isArray(departmentsOptions.selected) &&
        departmentsOptions.selected.length > 0
      ) {
        conditions.push({
          field: "departmentUuid",
          condition: "equals",
          value: departmentsOptions.selected
            .filter((option) => option.value !== null)
            .map((option) => option.value) as string[],
        });
      }

      if (
        countryOptionsActive &&
        Array.isArray(countryOptions.selected) &&
        countryOptions.selected.length > 0
      ) {
        conditions.push({
          field: "country",
          condition: "equals",
          value: countryOptions.selected
            .filter((option) => option.value !== null)
            .map((option) => option.value) as string[],
        });
      }

      if (
        stateOptionsActive &&
        Array.isArray(stateOptions.selected) &&
        stateOptions.selected.length > 0
      ) {
        conditions.push({
          field: "state",
          condition: "equals",
          value: stateOptions.selected
            .filter((option) => option.value !== null)
            .map((option) => option.value) as string[],
        });
      }

      const createSegmentResponse = await request({
        url: `/organizations/${organizationUuid}/expense-models/segments`,
        method: "POST",
        body: {
          name: nameInput.value,
          value: Number(amount.value) * 100,
          conditions,
        },
      });

      if (createSegmentResponse.status === 201) {
        setState((prevState) => {
          const newSegments = prevState.segments.filter(
            (segment) => segment.uuid !== segmentUuidToUpdate,
          );
          newSegments.push(createSegmentResponse.data.data);
          return {
            ...prevState,
            valid: newSegments.length > 0,
            touched: true,
            pristine: false,
            segments: newSegments,
          };
        });
        closeSegmentEditMode();
      } else {
        toast.error("Something went wrong");
      }
    } else {
      /**
       * Trigger error states
       */
      setState((prevState) => ({
        ...prevState,
        touched: true,
        pristine: false,
      }));
      setNameInput((prevState) => ({
        ...prevState,
        touched: true,
        pristine: false,
      }));
      setAmount((prevState) => ({
        ...prevState,
        touched: true,
        pristine: false,
      }));
    }
  };

  const showError = state.touched && !state.pristine && !state.valid;

  return (
    <div className="flex flex-col">
      <Typography weight="semibold" size="sm">
        Segments
      </Typography>
      <Typography size="xs" className="text-neutral-400">
        Determine target groupings for this expense model and the related costs
      </Typography>
      {state.segments.map((segment) => (
        <div
          key={segment.name}
          className="flex flex-col mt-2 border border-solid border-gray-300 rounded shadow-sm"
        >
          <div className="flex justify-between items-center px-3 py-2.5">
            <div className="flex gap-2 items-center">
              <Typography size="xs">{segment.name}</Typography>
              <Divider orientation="vertical" className="text-neutral-100" />
              <Typography size="xs">
                {positionType === "FIXED_PER_EMPLOYEE"
                  ? formatCurrency(segment.value, true)
                  : formatPercent(segment.value / 10000)}
              </Typography>
            </div>
            <div className="flex gap-2 justify-end">
              <Button
                fill="clear"
                className="!w-auto !py-1 !px-1"
                onClick={() => {
                  if (segment.uuid) enterEditSegmentMode(segment.uuid);
                }}
              >
                Edit
              </Button>
              <Button
                fill="clear"
                className="text-red-400 hover:!text-red-500 !w-auto !py-1 !px-1"
                onClick={() => {
                  if (segment.uuid) attemptDeleteSegment(segment.uuid);
                }}
              >
                Delete
              </Button>
            </div>
          </div>
        </div>
      ))}
      {showError && (
        <Typography className="text-red-500">
          At least 1 segment is required to save
        </Typography>
      )}
      <Button
        fill="clear"
        className="!px-0 !justify-start"
        onClick={() => {
          setSegmentEditMode(true);
        }}
      >
        + New Segment
      </Button>
      <Modal
        isOpen={segmentEditMode}
        onClose={() => setSegmentEditMode(false)}
        size="md"
      >
        <div className="flex flex-col w-full gap-5">
          <Typography size="xl" weight="bold">
            Create Segment
          </Typography>
          <Input
            id="segment-name"
            state={nameInput}
            setState={setNameInput}
            label="Segment Name"
          />
          <Divider />
          <div>
            <Typography size="xs">Employee Type</Typography>
            <div className="flex items-center mt-2">
              <Checkbox
                id="position-type-fulltime"
                checked={fullTimeChecked}
                toggleValue={() => setFullTimeChecked(!fullTimeChecked)}
                className="mr-1"
              />
              <Typography className="mr-3">Full Time</Typography>
              <Checkbox
                id="position-type-partime"
                checked={partTimeChecked}
                toggleValue={() => setPartTimeChecked(!partTimeChecked)}
                className="mr-1"
              />
              <Typography>Part Time</Typography>
            </div>
          </div>
          {departmentOptionsActive && (
            <div className={`w-full flex items-end`}>
              <SelectMultipleDepartments
                departmentOptions={departmentsOptions}
                setDepartmentOptions={setDepartmentsOptions}
                label="Select a department"
                className="max-w-[calc(100%-2.3rem)]"
              />
              <TrashIcon
                className="h-5 w-5 min-w-5 mb-3 ml-3 text-neutral-100 hover:text-red-500 cursor-pointer"
                onClick={() => {
                  setDepartmentOptionsActive(false);
                  resetDepartmentOptions();
                }}
              />
            </div>
          )}
          {countryOptionsActive && (
            <div className="w-full flex items-end">
              <SelectMultiple
                id="country"
                label="Country"
                state={countryOptions}
                setState={setCountryOptions}
                className="max-w-[calc(100%-2.3rem)]"
              />
              <TrashIcon
                className="h-5 w-5 mb-3 ml-3 text-neutral-100 hover:text-red-500 cursor-pointer"
                onClick={() => {
                  setCountryOptionsActive(false);
                  resetCountryOptions();
                }}
              />
            </div>
          )}
          {stateOptionsActive && (
            <div className="w-full flex items-end">
              <SelectMultiple
                id="state"
                label="State"
                state={stateOptions}
                setState={setStateOptions}
                className="max-w-[calc(100%-2.3rem)]"
              />
              <TrashIcon
                className="h-5 w-5 mb-3 ml-3 text-neutral-100 hover:text-red-500 cursor-pointer"
                onClick={() => {
                  setStateOptionsActive(false);
                  resetStateOptions();
                }}
              />
            </div>
          )}
          <Popover
            fill="clear"
            className="text-left !px-0"
            align="left"
            options={[
              {
                label: "Department",
                onClick: (): void => setDepartmentOptionsActive(true),
                disabled: departmentOptionsActive,
              },
              {
                label: "Country",
                onClick: (): void => setCountryOptionsActive(true),
                disabled: countryOptionsActive,
              },
              {
                label: "State",
                onClick: (): void => setStateOptionsActive(true),
                disabled: stateOptionsActive,
              },
            ]}
          >
            + Add Conditional
          </Popover>
          <Divider />
          <Typography className="leading-[0px]" size="xs">
            {positionTypeMapping[positionType].label}
          </Typography>
          {positionTypeMapping[positionType].sublabel !== "" && (
            <Typography
              weight="thin"
              color="empty"
              size="xs"
              className="leading-[0px]"
            >
              {positionTypeMapping[positionType].sublabel}
            </Typography>
          )}
          <Input
            id="segment-amount"
            state={amount}
            setState={setAmount}
            type={
              positionType === "FIXED_PER_EMPLOYEE" ? "currency" : "percentage"
            }
          />
          {/* This will be added in the future
          <div className="w-full bg-green-15 py-4 px-6 h-[88px] flex flex-col justify-between"> 
            <div className="flex justify-between">
              <Typography>
                Headcount
              </Typography>
              <Typography>
                300
              </Typography>
            </div>
            <div className="flex justify-between">
              <Typography>
                Cost
              </Typography>
              <Typography>
                $4000
              </Typography>
            </div>
          </div> */}
          <div className="flex justify-between gap-3 my-3">
            <Button
              id="cancel"
              fill="clear"
              className="!w-auto !px-0"
              onClick={closeSegmentEditMode}
            >
              Cancel
            </Button>
            <Button
              id="filter-save"
              className="!w-auto"
              onClick={attemptCreateSegment}
            >
              Save
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ExpenseModelSegments;
